import React from "react"

const Cookies = () => {
  return (
    <div className="policy mt-32 mx-4 center-content">
      <div className="max-w-screen-xl">
        <h1>Cookie-erklæring</h1>
        <p>
          På denne nettsiden benytter vi cookies. Denne cookie-erklæringen
          beskriver bruken av cookies på ladetannhelsesenter.no, samt hvilke til
          hvilke formål vi benytter cookies.
        </p>

        <h3>Definisjoner:</h3>
        <ul>
          <li>
            Cookies: en teknologi som oss lagre og lese informasjon i din
            nettleser, og det kan brukes til mange ulike formål.
          </li>
          <li>
            Førsteparts-cookies er cookies som er satt av og brukes av denne
            nettsiden,
          </li>
          <li>
            Tredjeparts-cookies er cookies som settes og brukes av tredjeparter,
            det vil si andre nettsider/tjenester vi benytter oss av på denne
            nettsiden.
          </li>
        </ul>

        <p>
          Denne nettsiden bruker både førsteparts-cookies og
          tredjeparts-cookies.
        </p>

        <h2>Formål</h2>

        <h3>Funksjonelle cookies</h3>
        <p>
          Dette er nødvendige cookies sørger for at denne nettsiden fungerer som
          den skal.
        </p>

        <h3>Analyse og statistikk</h3>
        <p>
          Dette er cookies som bruker til trafikkmålinger og analyse av
          bruksmønster etc.
        </p>

        <h3>Markedsføring</h3>
        <p>
          Dette er cookies som brukes til styre vår markedsføring, ved for
          eksempel å vise annonser på andre nettsider til de som har besøkt
          denne nettsiden.
        </p>
      </div>
    </div>
  )
}

export default Cookies
