import React, { useState } from "react"

type StateButtonOptions = {
  label: string
  onClick: (label?: string) => any
}

type StatePromptProps = {
  left: StateButtonOptions
  right: StateButtonOptions
  value?: "left" | "right" | null
}

const StatePrompt = ({ left, right, value = null }: StatePromptProps) => {
  const [state, setState] = useState<"left" | "right" | null>(value)

  const activeClasses = "border-primary-accent bg-blue-100"

  return (
    <div>
      <button
        className={`border-2 px-2 py-1 rounded cursor-pointer text-lg w-24 text-center shadow mr-4 ${
          state === "left" && activeClasses
        }`}
        onClick={() => {
          setState("left")
          left.onClick()
        }}
      >
        {left.label}
      </button>
      <button
        className={`border-2 px-2 py-1 rounded cursor-pointer text-lg w-24 text-center shadow ${
          state === "right" && activeClasses
        }`}
        onClick={() => {
          setState("right")
          right.onClick()
        }}
      >
        {right.label}
      </button>
    </div>
  )
}

export default StatePrompt
