import { useContext } from "react"
import { Link, useNavigate } from "react-router-dom"
import AdminContext from "../../../contexts/admin-context"
import { adminService } from "../../../services/services"
import LogoAnimation from "../../../assets/images/logo/logo.gif"

const AdminNavbar = () => {
  const { admin, setAdmin } = useContext(AdminContext)
  const navigate = useNavigate()

  async function logout() {
    await adminService.logout()
    setAdmin(null)
  }

  return (
    <div className="fixed h-24 z-30 top-0 w-full shadow-lg flex flex-col items-center bg-background-light">
      <div className="w-full h-full flex items-center justify-between">
        <a
          href="/ansatt"
          className="relative flex-1 px-4 select-none cursor-pointer text-primary"
        >
          <img src={LogoAnimation} alt="logo" className="p-4 w-48 md:w-64" />
        </a>
        {admin && (
          <div className="hidden lg:flex flex-1 h-full items-center justify-around">
            <Link
              to="ansatt/chat"
              className="text-xl text-primary-accent font-bold hover:underline"
            >
              Chat
            </Link>
            {/* <Link
              to="ansatt/tekster"
              className="text-xl text-primary-accent font-bold hover:underline"
            >
              Tekster
            </Link> */}
            <Link
              to="ansatt/behandlinger"
              className="text-xl text-primary-accent font-bold hover:underline"
            >
              Behandlinger
            </Link>
            <Link
              to="ansatt/innstillinger"
              className="text-xl text-primary-accent font-bold hover:underline"
            >
              Innstillinger
            </Link>
          </div>
        )}
        {admin && (
          <div className="center-content">
            <p className="hidden md:inline-block">{admin.email}</p>
            <button
              className="text-primary-accent border-2 border-primary-accent rounded-full py-2 px-4 m-4 whitespace-nowrap text-lg font-bold"
              onClick={logout}
            >
              Logg ut
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

export default AdminNavbar
