import { Link } from "react-router-dom"

type TopicalCardProps = {
  id: string
  title: string
  description: string
  image: string
}

const TopicalCard = (props: TopicalCardProps) => {
  return (
    <Link
      to={`/info/${props.id}`}
      className="bg-primary-accent text-light rounded shadow overflow-hidden cursor-pointer hover:scale-[101%] transition-all duration-200"
    >
      <img
        src={props.image}
        alt={props.title}
        className="w-full h-48 lg:h-64 object-cover"
      />
      <div className="p-2">
        <h2 className="text-xl font-bold">{props.title}</h2>
        <h3 className="text-lg">{props.description}</h3>
      </div>
    </Link>
  )
}

export const ComingSoonCard = () => {
  return (
    <div className="border-2 border-primary-accent text-nature border-dashed rounded-lg center-content flex-col min-h-[16rem]">
      <h3 className="text-lg">Her kommer det mer snart!</h3>
    </div>
  )
}

export default TopicalCard
