import { useState } from "react"
import { ChatConfig } from "../../services/chat-service"
import { chatService } from "../../services/services"
import LoadingCover from "../base/loading-cover/loading-cover"

const AdminSettings = () => {
  const [saving, setSaving] = useState<boolean>(false)
  const [chatConfig, setChatConfig] = useState<ChatConfig | null>(null)

  useState(async () => {
    const chatConfig = await chatService.getConfig().catch((error) => {
      console.error(error)
    })
    if (chatConfig) setChatConfig(chatConfig)
  })

  async function saveChatConfig() {
    if (!chatConfig) return
    setSaving(true)
    await chatService.updateConfig(chatConfig)
    setTimeout(() => setSaving(false), 500)
  }

  if (!chatConfig) return <LoadingCover className="!h-screen" />

  return (
    <div className="relative p-4 text-primary-accent">
      <LoadingCover
        text="Lagrer..."
        show={saving}
        className="absolute top-0 left-0 h-full w-full"
      />
      <div className="w-full border-b-2 border-primary-accent mb-8">
        <h1 className="text-3xl mb-2">Admin Innstillinger</h1>
      </div>
      <div>
        <h2 className="text-2xl mb-2">Chat</h2>
        <div className="">
          <div className="flex flex-row items-center mb-2">
            <label className="mr-2">Aktiv:</label>
            <input
              type="checkbox"
              className="mt-1"
              checked={chatConfig.chatEnabled}
              onChange={(e) => {
                setChatConfig({ ...chatConfig, chatEnabled: e.target.checked })
              }}
            />
          </div>
          <div className="mb-4">
            <label className="mr-2 block mb-2">Avslått melding</label>
            <textarea
              className="border-2 border-primary-accent px-1 w-full max-w-[24rem]"
              value={chatConfig.chatDisabledMessage}
              onChange={(e) => {
                setChatConfig({
                  ...chatConfig,
                  chatDisabledMessage: e.target.value,
                })
              }}
            />
          </div>
          <div className="flex flex-row items-center mb-2">
            <label className="mr-2">Automatisk svar:</label>
            <input
              type="checkbox"
              className="mt-1"
              checked={chatConfig.autoReply}
              onChange={(e) => {
                setChatConfig({ ...chatConfig, autoReply: e.target.checked })
              }}
            />
          </div>
          <textarea
            className="border-2 border-primary-accent px-1 w-full max-w-[24rem]"
            value={chatConfig.autoReplyMessage}
            onChange={(e) => {
              setChatConfig({
                ...chatConfig,
                autoReplyMessage: e.target.value,
              })
            }}
          />
        </div>
      </div>
      <button
        className="fixed bottom-0 right-0 m-4 mb-20 md:mb-4 text-light bg-primary-accent text-xl rounded-lg px-4 py-2"
        onClick={saveChatConfig}
      >
        Lagre
      </button>
    </div>
  )
}

export default AdminSettings
