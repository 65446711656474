import { FaBuilding, FaClock, FaEnvelope, FaPhone } from "react-icons/fa"
import atb from "../../assets/images/atb_logo.webp"
import FacebookLogo from "../../assets/images/social/facebook_logo.webp"
import InstagramLogo from "../../assets/images/social/instagram_logo.webp"

const MapAndDirections = () => {
  return (
    <div className="w-full max-h-80vh p-8 pb-24 lg:pb-8 overflow-auto">
      <div className="grid lg:grid-cols-3 gap-8 mt-4">
        <div>
          <h3 className="font-bold text-xl mb-4">Kart & veibeskrivelse</h3>
          <div className="w-full" style={{ height: "400px" }}>
            <iframe
              className="rounded-xl border border-primary-accent"
              title="google maps"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4901.469779411923!2d10.434517596793683!3d63.444435861213854!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x466d3105988fa5bd%3A0x2c461931548ed354!2sH%C3%A5kon%20Magnussons%20gate%2010%2C%207041%20Trondheim!5e0!3m2!1sen!2sno!4v1695026632581!5m2!1sen!2sno"
              width="100%"
              height="100%"
              allowFullScreen={false}
              aria-hidden="false"
              tabIndex={0}
            />
          </div>
        </div>
        <div className="flex flex-col justify-between">
          <div>
            <h3 className="font-bold text-xl mb-4">Kontakt oss</h3>
            <div className="flex flex-row items-center mb-4">
              <FaPhone className="text-focus mr-4" />
              <span>73 52 00 00</span>
            </div>
            <div className="flex flex-row mb-4">
              <FaBuilding className="text-focus mr-4 mt-1" />
              <div>
                <span className="block">Lade Tannhelsesenter</span>
                <span className="block">Håkon Magnussons gate 10</span>
                <span className="block">7041 Trondheim</span>
                <span className="block">Org. nr: 931 825 720</span>
              </div>
            </div>
            <div className="flex flex-row items-center mb-4">
              <FaEnvelope className="text-focus mr-4" />
              <span>post@ladetannhelsesenter.no</span>
            </div>
            <div className="flex flex-row items-center justify-center mb-4">
              <a
                href="https://www.facebook.com/profile.php?id=61551016539486"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={FacebookLogo}
                  className="h-[2.8rem] w-auto rounded-lg mr-4"
                  alt="facebook"
                />
              </a>
              <div className="w-2" />
              <a
                href="https://www.instagram.com/ladetannhelsesenter/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={InstagramLogo}
                  className="h-[3rem] w-auto"
                  alt="instagram"
                />
              </a>
            </div>
          </div>
          <div>
            <div>
              <a
                href="https://www.google.com/maps/dir//H%C3%A5kon+Magnussons+gate+10,+7041+Trondheim/@63.4444187,10.436472,16z/data=!4m18!1m8!3m7!1s0x466d3105988fa5bd:0x2c461931548ed354!2sH%C3%A5kon+Magnussons+gate+10,+7041+Trondheim!3b1!8m2!3d63.4444164!4d10.4416218!16s%2Fg%2F11c43stgcc!4m8!1m0!1m5!1m1!1s0x466d3105988fa5bd:0x2c461931548ed354!2m2!1d10.4416218!2d63.4444164!3e1?hl=en"
                target="_blank"
                rel="noreferrer noopener"
              >
                <button
                  className="w-64 text-gray-100 font-bold rounded-full py-2 px-4 mr-4 shadow hover:shadow-xl transition duration-200 mb-4"
                  style={{ backgroundColor: "#333e46" }}
                >
                  Vis veibeskrivelse
                </button>
              </a>
            </div>
            <div className="flex flex-row items-center">
              <a
                href="https://www.atb.no/reiseplanlegger/?direction=1&from=&to= Håkon Magnussons gate 10"
                target="_blank"
                rel="noreferrer noopener"
              >
                <button
                  className="text-gray-100 w-64 font-bold rounded-full py-2 px-4 mr-4 shadow hover:shadow-xl transition duration-200"
                  style={{ backgroundColor: "#333e46" }}
                >
                  Planlegg reise med AtB
                </button>
              </a>
              <img className="h-10 w-10" src={atb} alt="atb" />
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col justify-between">
          <div>
            <div className="flex flex-row items-center mb-2">
              <FaClock size={24} className="text-focus mr-2" />
              <h3 className="font-bold text-xl">Åpningstider</h3>
            </div>
            <div>
              <div className="flex flex-row items-center justify-between border-b mb-2">
                <p className="text-xl">Mandag:</p>
                <p>08:00 - 19:00</p>
              </div>
              <div className="flex flex-row items-center justify-between border-b mb-2">
                <p className="text-xl">Tirsdag:</p>
                <p>08:00 - 19:00</p>
              </div>
              <div className="flex flex-row items-center justify-between border-b mb-2">
                <p className="text-xl">Onsdag:</p>
                <p>08:00 - 19:00</p>
              </div>
              <div className="flex flex-row items-center justify-between border-b mb-2">
                <p className="text-xl">Torsdag:</p>
                <p>08:00 - 19:00</p>
              </div>
              <div className="flex flex-row items-center justify-between border-b mb-2">
                <p className="text-xl">Fredag:</p>
                <p>08:00 - 16:00</p>
              </div>
              <div className="flex flex-row items-center justify-between border-b mb-2">
                <p className="text-xl">Lørdag:</p>
                <p>Vakttelefon</p>
              </div>
              <div className="flex flex-row items-center justify-between">
                <p className="text-xl mb-2">Søndag:</p>
                <p>Vakttelefon</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MapAndDirections
