export type Treatment = {
  id: number | string
  name: string
  description?: string
  price?: number | string
  priceNote?: string
  image?: string
  treatments?: Treatment[]
}

export const treatments: Treatment[] = [
  {
    id: 1,
    name: "Undersøkelse",
    description:
      "Undersøkelse hos allmennpraktiserende tannlege. Dette inkluderer 2 røntgenbilder samt puss og rens",
    price: 490,
    treatments: [
      {
        id: 12,
        name: "Akutt undersøkelse",
        price: 690,
      },
      {
        id: 11,
        name: "Undersøkelse hos allmennpraktiserende tannlege",
        price: 790,
      },
    ],
  },
  {
    id: 16,
    name: "Bedøvelse",
    price: 240,
    treatments: [
      {
        id: 161,
        name: "Lokalbedøvelse",
        price: 240,
      },
      {
        id: 162,
        name: "Lokalbedøvelse med The Wand",
        price: 380,
      },
      {
        id: 163,
        name: "Premedisinering/våken sedasjon",
        price: 790,
      },
    ],
  },
  {
    id: 2,
    name: "Puss og Rens (air flow)",
    price: 290,
    treatments: [
      {
        id: 21,
        name: "Enkel rens i forbindelse med undersøkelse",
        price: "290",
      },
      {
        id: 22,
        name: "Full puss og rens",
        price: "900",
      },
    ],
  },
  {
    id: 3,
    name: "Røntgen",
    price: 220,
    treatments: [
      {
        id: 31,
        name: "Intraoral røntgen",
        price: 220,
      },
      {
        id: 32,
        name: "OPG (panoramarøntgen)",
        price: 790,
      },
      // {
      //   id: 33,
      //   name: "CBCT (3D-røntgen)",
      //   price: 2450,
      // },
    ],
  },
  {
    id: 4,
    name: "Fyllingsterapi",
    price: 600,
    treatments: [
      {
        id: 46,
        name: "Midlertidig fylling",
        price: "600",
      },
      {
        id: 41,
        name: "Tannfarget fylling, 1 flate",
        price: "790 - 1490",
      },
      {
        id: 42,
        name: "Tannfarget fylling, 2 flate",
        price: "1490 - 1990",
      },
      {
        id: 43,
        name: "Tannfarget fylling, 3 flate",
        price: "1990 - 2400",
      },
      {
        id: 45,
        name: "Oppbygging av hel tannkrone",
        price: "2690 - 3300",
      },
    ],
  },
  {
    id: 5,
    name: "Tannkjøttbehandling",
    price: 790,
    treatments: [
      {
        id: 51,
        name: "Undersøkelse, lommeregistering og behandlingsplannlegging",
        price: 790,
      },
      {
        id: 52,
        name: "Dyp rens per halvtime",
        price: "950 - 1500",
      },
      {
        id: 53,
        name: "Periodontal kirurgi",
        price: 2900,
      },
      // {
      //   id: 54,
      //   name: "Tannkjøttbehandling ved hjelp av Laser",
      //   priceNote: "Tidsdebitering",
      // },
    ],
  },
  {
    id: 6,
    name: "Rotfylling",
    price: 4900,
    treatments: [
      {
        id: 61,
        name: "Rotfylling av fortann/hjørnetann",
        price: 4900,
      },
      {
        id: 62,
        name: "Rotfylling av premolar - liten jeksel",
        price: 6100,
      },
      {
        id: 63,
        name: "Rotfylling av molar - stor jeksel",
        price: 7200,
      },
    ],
  },
  {
    id: 7,
    name: "Krone / Bro",
    price: 7950,
    treatments: [
      {
        id: 71,
        name: "Krone",
        price: 7950,
      },
      {
        id: 72,
        name: "Stift",
        price: 2490,
      },
      {
        id: 73,
        name: "Skallfasetter (venners)",
        price: 8900,
      },
      {
        id: 74,
        name: "Implantatkrone",
        price: 14500,
      },
    ],
  },
  {
    id: 8,
    name: "Proteser",
    price: 15900,
    treatments: [
      {
        id: 81,
        name: "Delprotese",
        price: 15900,
      },
      {
        id: 82,
        name: "Helprotese - en kjeve",
        price: 14900,
      },
      {
        id: 83,
        name: "Helprotese - begge kjever",
        price: 27500,
      },
      {
        id: 84,
        name: "Rebasering",
        price: 5000,
      },
      {
        id: 85,
        name: "Enkel/Immediat protese",
        price: 5000,
      },
    ],
  },
  {
    id: 9,
    name: "Implantater",
    price: 17500,
    treatments: [
      {
        id: 91,
        name: "Implantat operasjon",
        price: 17500,
      },
      {
        id: 92,
        name: "Kirurgisk guideskinne ved implantatbehandling",
        price: 4500,
      },
    ],
  },
  {
    id: 10,
    name: "Ekstraksjon",
    price: 1545,
    treatments: [
      {
        id: 101,
        name: "Enkel ekstraksjon",
        price: 1545,
      },
      {
        id: 102,
        name: "Komplisert ekstraksjon",
        price: 2490,
      },
    ],
  },
  {
    id: 11,
    name: "Kirurgi behandling",
    price: 3900,
    treatments: [
      {
        id: 111,
        name: "Kirurgisk fjerning av tann/rot",
        price: 3900,
      },
      {
        id: 112,
        name: "Rotamputasjon frontann/hjørnetann/premolar",
        price: 4000,
      },
      {
        id: 113,
        name: "Kirurgisk etterkontroll",
        price: 490,
      },
    ],
  },
  {
    id: 12,
    name: "Tannbleking",
    price: 2900,
    treatments: [
      {
        id: 121,
        name: "En kjeve",
        price: 2900,
      },
      {
        id: 122,
        name: "Begge kjever",
        price: 4500,
      },
    ],
  },
  // {
  //   id: 13,
  //   name: "Laserbehandling",
  //   priceNote: "Tidsdebitering",
  // },
  {
    id: 14,
    name: "Invisalign (usynlig tannregulering)",
    price: 490,
    treatments: [
      {
        id: 140,
        name: "Konsultasjon",
        priceNote: "490 NOK",
      },
      {
        id: 141,
        name: "Invisalign",
        priceNote: "20000 - 59000 NOK",
      },
      {
        id: 142,
        name: "Retainer per kjeve",
        price: 1400,
      },
      {
        id: 143,
        name: "Vivera skinner per kjeve",
        price: 2500,
      },
    ],
  },
  {
    id: 15,
    name: "Diverse tjenester",
    priceNote: " ",
    treatments: [
      {
        id: 151,
        name: "Bittskinne",
        price: 3250,
      },
      {
        id: 152,
        name: "Refill blekemiddel",
        price: 200,
      },
      {
        id: 153,
        name: "Henvisning/Resept/Sykemelding",
        price: 100,
      },
      {
        id: 154,
        name: "Faktura gebyr",
        price: 95,
      },
      {
        id: 155,
        name: "Fravær gebyr (ikke møtt til time)",
        price: 600,
      },
      {
        id: 156,
        name: "Tannfoto",
        price: 90,
      },
      {
        id: 157,
        name: "Digital scanning av kjeven",
        price: 490,
      },
      {
        id: 158,
        name: "Kirurgisk/Endo oppdekking",
        price: 390,
      },
    ],
  },
]

export enum DAY {
  "monday" = "Mandag",
  "tuesday" = "Tirsdag",
  "wednesday" = "Onsdag",
  "thursday" = "Torsdag",
  "friday" = "Fredag",
  "saturday" = "Lørdag",
  "sunday" = "Søndag",
}

export const availableTimes = [
  "08:00 - 10:00",
  "10:00 - 12:00",
  "12:00 - 14:00",
  "14:00 - 16:00",
]

export const dentistsOptions: any[] = [
  { value: "0", label: "Ingen ønske" },
  { value: "1", label: "Ehsan Smith" },
  { value: "2", label: "Runa Sarre" },
]

export const employees: { [email: string]: string } = {
  system: "System",
  "erlingroll98@gmail.com": "Erling Roll",
  "smith-ehsan@hotmail.com": "Ehsan Smith",
  "sarre.runa@gmail.com": "Runa Sarre Timestad",
}
