// Assets
import { Helmet } from "react-helmet"
import OrderButton from "../base/order-button/order-button"
import SkallfasetterImg from "../../assets/images/skallfasetter.webp"

const Skallfasetter = () => {
  return (
    <div className="info w-full center-content p-8 text-dark">
      <Helmet>
        <title>Skall-fasetter Trondheim</title>
      </Helmet>
      <div className="max-w-screen-xl">
        <div className="center-content flex-col mb-8 text-center">
          <h1>Skallfasetter for tenner: Komplett guide</h1>
          <p className={`!mb-8 mt-2`}>
            Estetisk tannbehandling med skallfasetter er en populær metode for
            penere tenner.
          </p>
          <img
            src={SkallfasetterImg}
            alt="skaalfasetter"
            className="w-full max-h-[60vh] object-cover object-center"
          />
        </div>
        <div className="mb-8">
          <h2>Introduksjon</h2>
          <p>
            Skallfasetter, også kjent som tannfasetter eller veneers, er en
            populær løsning for mange som ønsker å gjøre tennene sine penere. I
            denne artikkelen vil vi gå i dybden på hva disse fasettene er,
            hvordan de fungerer, og hvilke fordeler de kan tilby. Enten du
            vurderer skallfasetter for første gang eller bare ønsker mer
            informasjon, vil denne guiden dekke alt du trenger å vite.
          </p>
        </div>
        <div className="mb-8">
          <h2>Hva er skallfasetter?</h2>
          <p>
            Skallfasetter, eller veneers, er tynne skall laget av porselen eller
            komposittmateriale som festes til forsiden av tennene for å forbedre
            deres utseende. Disse fasettene kan brukes til å korrigere en rekke
            tannproblemer, inkludert misfarget emalje, slitte eller ødelagte
            tenner, ujevn tannoverflate og mellomrom mellom tennene.
          </p>
        </div>

        <div className="mb-8">
          <h2>Hvordan fungerer de?</h2>
          <p>
            Disse tannfasettene lages individuelt for hver pasient for å sikre
            et naturlig og tilpasset utseende. Prosessen starter med en
            konsultasjon hos tannlegen, hvor dine mål og forventninger blir
            diskutert. Tannlegen vil deretter ta avtrykk av tennene dine for å
            lage en modell. Basert på denne modellen blir fasettene skreddersydd
            for å passe perfekt til dine tenner. Når fasettene er klare, vil
            tannlegen fjerne en liten mengde emalje fra forsiden av tennene for
            å gi plass til dem. Deretter festes de med et sterkt lim og justeres
            for å sikre at de ser naturlige ut og føles komfortable.
          </p>
        </div>
        <div className="mb-8">
          <h2>Fordeler med tannfasetter</h2>
          <p>
            Det er mange fordeler med å velge denne løsningen for å forbedre
            tennenes utseende. Noen av de viktigste fordelene inkluderer:
          </p>
          <ul className="list-disc pl-4 mb-4">
            <li>
              <strong>Forbedret estetikk:</strong> Tannfasetter kan gi tennene
              dine et jevnere og mer harmonisk utseende.
            </li>
            <li>
              <strong>Naturlig utseende:</strong> Fordi fasettene er laget av
              materialer som ligner naturlige tenner, ser de svært naturlige ut.
            </li>
            <li>
              <strong>Holdbarhet:</strong> Med riktig pleie kan de vare i mange
              år.
            </li>
            <li>
              <strong>Minimalt inngrep:</strong> Prosedyren for å sette på
              fasettene krever vanligvis mindre fjerning av tannemalje
              sammenlignet med andre alternativer.
            </li>
          </ul>
        </div>
        <div className="mb-8">
          <h2>Ulemper med veneers</h2>
          <p>
            Selv om veneers har mange fordeler, er det også noen ulemper å
            vurdere:
          </p>
          <ul className="list-disc pl-4 mb-4">
            <li>
              <strong>Kostnad:</strong> Tannfasetter kan være kostbare, spesielt
              hvis flere tenner skal behandles. Det er viktig å diskutere
              prisene med tannlegen din på forhånd.
            </li>
          </ul>
        </div>
        <div className="mb-8">
          <h2>Skallfasetter uten sliping</h2>
          <p>
            For de som ønsker en mindre invasiv behandling, finnes det
            alternativer som fasetter uten sliping, også kjent som "non-prep
            veneers". Disse fasettene krever ikke fjerning av emalje, noe som
            gjør prosedyren raskere og mer skånsom. Dette kan være et godt
            alternativ for de som ønsker å bevare så mye som mulig av sin
            naturlige tannstruktur.
          </p>
        </div>
        <div className="mb-8">
          <h2>Før og etter bilder</h2>
          <p>
            En av de mest overbevisende måtene å forstå effekten av tannfasetter
            på er å se før og etter bilder. Disse bildene viser hvordan tennene
            kan transformeres og gi deg en idé om hva du kan forvente av
            behandlingen. Hos Lade Tannhelsesenter tilbyr vi konsultasjon der vi
            kan vise deg eksempler på tidligere arbeid og diskutere hvordan
            fasetter kan forbedre ditt smil.
          </p>
        </div>
        <div className="mb-8">
          <h2>Skallfasetter pris</h2>
          <p>
            Kostnaden for tannfasetter varierer avhengig av flere faktorer,
            inkludert materialet som brukes og tannlegens erfaring. Hos Lade
            Tannhelsesenter koster disse fasettene mellom 7950-10500 kr per
            tann. Vi anbefaler at du diskuterer dine spesifikke behov og får et
            detaljert kostnadsoverslag under konsultasjonen.
          </p>
        </div>
        <div className="mb-8">
          <h2>Vedlikehold av tannfasetter</h2>
          <p>
            Vedlikehold av fasetter er relativt enkelt, men det krever at du
            følger noen grunnleggende retningslinjer:
          </p>
          <ul className="list-disc pl-4 mb-4">
            <li>
              <strong>God munnhygiene:</strong> Børst tennene to ganger om dagen
              og bruk tanntråd daglig.
            </li>
            <li>
              <strong>Regelmessige tannlegebesøk:</strong> Besøk tannlegen din
              for regelmessige kontroller og profesjonell rengjøring.
            </li>
            <li>
              <strong>Vær forsiktig med veldig harde matvarer:</strong> I de
              aller fleste tilfeller er dette ikke noe problem, men man bør være
              litt varsom for veldig harde matvarer.
            </li>
          </ul>
        </div>
        <div className="mb-8">
          <h2>Når du bør kontakte tannlegen</h2>
          <p>
            Hvis du opplever problemer med tennene dine eller er interessert i å
            forbedre deres utseende, bør du kontakte tannlegen din for en
            konsultasjon. Spesielt hvis du har skader, misfarging, eller
            mellomrom mellom tennene som du ønsker å rette opp, kan fasetter
            være en god løsning for deg. Hos Lade Tannhelsesenter er vi her for
            å hjelpe deg gjennom hele prosessen, fra første konsultasjon til
            ferdig resultat. Vi tilbyr også informasjon om prisene og ulike
            betalingsalternativer for å gjøre behandlingen så tilgjengelig som
            mulig. Ta kontakt med oss i dag for å lære mer om hvordan vi kan
            hjelpe deg med å få det smilet du alltid har ønsket.
          </p>
        </div>
        <div className="center-content mt-12 mb-10">
          <OrderButton />
        </div>
      </div>
    </div>
  )
}

export default Skallfasetter
