import { AxiosInstance } from "axios"

export type Treatment = {
  id: string
  name: string
  description: string
  price: number | null
  priceNote: string | null
  imageUrl: string | null
  order?: number
}

export default class TreatmentService {
  private client: AxiosInstance

  constructor(client: AxiosInstance) {
    this.client = client
  }

  public updateTreatment = async (text: Treatment) => {
    const response = await this.client.post("/admin/treatment", text)
    return response.data
  }

  public getTreatmentList = async () => {
    const response = await this.client.get("/treatments/list")
    return response.data
  }

  public getTreatment = async (id: string) => {
    const response = await this.client.get(`/treatment/${id}`)
    return response.data
  }
}
