import { employees } from "../../../constants/constants"
import { ChatMessage as ChatMessageType } from "../chat"

type ChatMessageProps = {
  message: ChatMessageType
  isAdmin?: boolean
  firstEmployeeMessage?: boolean
}

const ChatMessage = ({
  message,
  isAdmin,
  firstEmployeeMessage,
}: ChatMessageProps) => {
  const isMyMessage =
    (message.employee && isAdmin) || (!message.employee && !isAdmin)
  const isSystemMessage =
    message.chatId === "system" && message.employee?.id === "system"

  return (
    <div
      className={`${
        isMyMessage ? "ml-8 text-right" : "mr-8 text-left"
      } m-2 px-4`}
    >
      {message.employee && (
        <p className="text-xs mb-1">{employees[message.employee.email]}</p>
      )}
      <div
        className={`${
          message.employee ? "bg-green-200" : "bg-gray-200"
        } inline-block py-2 px-4 rounded-lg whitespace-normal`}
        style={{
          [isMyMessage ? "borderTopRightRadius" : "borderTopLeftRadius"]: "0",
        }}
      >
        <p className={isSystemMessage ? "text-sm" : "text-base"}>
          {message.message}
        </p>
        {!isSystemMessage && (
          <p className="text-gray-400 text-sm">
            {new Date(message.createdAt).toLocaleString("no-nb", {
              month: "short",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
            })}
          </p>
        )}
      </div>
    </div>
  )
}

export default ChatMessage
