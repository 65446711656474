import { FaMinus, FaPlus } from "react-icons/fa"
import { Treatment } from "../../../constants/constants"
import { useRef, useState } from "react"

const TreatmentPrice = ({ treatment }: { treatment: Treatment }) => {
  const [isOpen, setIsOpen] = useState(false)

  const treatmentRef = useRef<HTMLDivElement>(null)

  return (
    <div className="shadow-lg">
      <div
        className="bg-primary-accent text-gray-100 flex flex-row justify-between items-center p-2 lg:p-4 cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h2 className="lg:text-xl mr-2 break-all">{treatment.name}</h2>
        <div className="flex flex-row items-center">
          <span className="mr-2 text-sm lg:text-xl whitespace-nowrap">
            {treatment.priceNote || `Fra ${treatment.price} NOK`}
          </span>
          {isOpen ? <FaMinus size={16} /> : <FaPlus size={16} />}
        </div>
      </div>
      <div
        className="transition-all duration-500 ease-in-out overflow-hidden"
        style={{ height: isOpen ? treatmentRef.current?.scrollHeight : 0 }}
      >
        <div ref={treatmentRef}>
          {treatment.treatments?.map((t, index) => (
            <div
              key={index}
              className="flex flex-col lg:flex-row justify-between items-start lg:items-center p-2 lg:p-4 lg:text-xl"
            >
              <h2 className="lg:text-xl mr-2">{t.name}</h2>
              {t.priceNote ? (
                <span className="lg:text-xl">{t.priceNote}</span>
              ) : (
                <span className="lg:text-xl whitespace-nowrap">
                  {t.price} NOK
                </span>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default TreatmentPrice
