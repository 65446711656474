import { AxiosInstance } from "axios"

export type ChatConfig = {
  chatEnabled: boolean
  chatDisabledMessage: string
  autoReply: boolean
  autoReplyMessage: string
}

export default class ChatService {
  private client: AxiosInstance

  constructor(client: AxiosInstance) {
    this.client = client
  }

  public updateConfig = async (config: ChatConfig) => {
    const response = await this.client.post("/admin/chat/config", config)
    return response.data
  }

  public getConfig = async () => {
    const response = await this.client.get("/chat/config")
    return response.data
  }
}
