import { AxiosInstance } from "axios"

export default class AdminService {
  private client: AxiosInstance

  constructor(client: AxiosInstance) {
    this.client = client
  }

  public login = async (email: string, password: string) => {
    const response = await this.client.post("/admin/login", { email, password })
    return response.data
  }

  public logout = async () => {
    const response = await this.client.post("/admin/logout")
    return response.data
  }

  public getAdmin = async () => {
    const response = await this.client.get("/admin")
    return response.data
  }
}
