import { useEffect, useState } from "react"
import Logo from "../../../assets/images/logo/logo_white_cropped.webp"

interface LoadingCoverProps extends React.HTMLAttributes<HTMLDivElement> {
  show?: boolean
  text?: string
}

const LoadingCover = ({
  show = true,
  className,
  text = "Laster...",
  ...rest
}: LoadingCoverProps) => {
  const [onLoadClasses, setOnLoadClasses] = useState<string>("opacity-0")

  useEffect(() => {
    setTimeout(() => {
      setOnLoadClasses("opacity-75")
    }, 100)
  }, [])

  return show ? (
    <div
      className={`${onLoadClasses} center-content flex-col h-full w-full bg-gray-500 backdrop-blur ${className}`}
      style={{ transition: "opacity 0.5s ease-in-out" }}
      {...rest}
    >
      <img src={Logo} alt="loading" className="motion-safe:animate-spin-slow" />
      <h1 className="text-3xl text-gray-100 font-bold">{text}</h1>
    </div>
  ) : (
    <></>
  )
}

export default LoadingCover
