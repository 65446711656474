type OrderButtonProps = {
  text?: string
  onClick?: () => any
}

const OrderButton = ({ text = "Bestill time", onClick }: OrderButtonProps) => {
  const handleOnClick = () => {
    onClick
      ? onClick()
      : (window.location.href = "https://myanita.no/clinic/41")
  }

  return (
    <button
      type="button"
      className="text-lg font-bold bg-focus rounded-full p-2 px-4 text-primary-accent shadow-lg hover:!bg-opacity-75 hover:scale-105 transition-all duration-100 ease-in-out"
      onClick={handleOnClick}
    >
      {text}
    </button>
  )
}

export default OrderButton
