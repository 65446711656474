import { FormEvent, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

// Components
import Select from "react-select"
import StatePrompt from "../base/state-prompt/state-prompt"

// Constants
import {
  treatments,
  DAY,
  availableTimes,
  Treatment,
  dentistsOptions,
} from "../../constants/constants"
import SelectTime from "./select-time"

const Order = () => {
  // Params
  const [search, setSearch] = useSearchParams()

  // State
  const [order, setOrder] = useState<Treatment | null>(
    treatments.find((t) => t.id === search.get("treatment")) || treatments[0]
  )
  const [name, setName] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [address, setAddress] = useState("")
  const [postCode, setPostCode] = useState("")
  const [email, setEmail] = useState("")
  const [newPatient, setNewPatient] = useState<boolean | null>(null)
  const [enterpriseAgreement, setEnterpriseAgreement] = useState<
    boolean | null
  >(null)
  const [student, setStudent] = useState<boolean | null>(null)
  const [dentist, setDentist] = useState<string | null>(dentistsOptions[0])
  const [day, setDay] = useState<DAY | null>(null)
  const [time, setTime] = useState<string | null>(null)

  // Hooks
  const navigate = useNavigate()

  // Methods
  const activeClasses = "border-primary-accent bg-blue-100"

  function submitHandler(e: FormEvent) {
    e.preventDefault()
  }

  return (
    <div className="center-content">
      <div className="w-full pt-4">
        <div className="relative p-2 lg:p-8 rounded-xl">
          <div className="p-0 lg:p-8">
            <div className="flex flex-row justify-between">
              <h1 className="text-4xl text-primary-accent">Bestill time</h1>
              <button
                className="bg-primary-accent w-60 px-2 pb-1 rounded text-gray-100 font-bold text-xl hover:bg-primary-accent-dark"
                onClick={() =>
                  (window.location.href = "https://myanita.no/clinic/41")
                }
              >
                Se oversikt over behandlinger og priser
              </button>
            </div>
            <form onSubmit={submitHandler}>
              <div className="my-4">
                <h2 className="text-lg text-primary-accent mb-2">
                  Velg behandling
                </h2>
                <div>
                  <div>
                    <Select
                      options={treatments}
                      onChange={(option) => {
                        if (option) setOrder(option)
                      }}
                      value={order}
                      placeholder="Velg en behandling"
                    />
                  </div>
                </div>
              </div>

              <div className="block lg:flex flex-row justify-start">
                <div className="lg:mr-20">
                  <h3 className="text-lg text-primary-accent mt-4 mb-1">
                    Er du ny en pasient til klinikken?
                  </h3>
                  <StatePrompt
                    left={{ label: "Ja", onClick: () => setNewPatient(true) }}
                    right={{
                      label: "Nei",
                      onClick: () => setNewPatient(false),
                    }}
                  />
                </div>
                <div className="lg:mr-20">
                  <h3 className="text-lg text-primary-accent mt-4 mb-1">
                    Bedriftsavtale?
                  </h3>
                  <StatePrompt
                    left={{
                      label: "Ja",
                      onClick: () => setEnterpriseAgreement(true),
                    }}
                    right={{
                      label: "Nei",
                      onClick: () => setEnterpriseAgreement(false),
                    }}
                  />
                </div>
                <div className="">
                  <h3 className="text-lg text-primary-accent mt-4 mb-1">
                    Student?
                  </h3>
                  <StatePrompt
                    left={{ label: "Ja", onClick: () => setStudent(true) }}
                    right={{ label: "Nei", onClick: () => setStudent(false) }}
                  />
                </div>
              </div>

              <div className="mt-4">
                <div className="mb-4">
                  <h3 className="text-lg text-primary-accent mb-1">
                    Ønsket tannlege
                  </h3>
                  <Select
                    options={dentistsOptions}
                    onChange={(option) => {
                      if (option) setDentist(option)
                    }}
                    value={dentist}
                    placeholder="Velg ønsket tannlege"
                  />
                </div>
              </div>

              <SelectTime />
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Order
