import { useEffect, useState } from "react"
import { InfoTextMap } from "../../../services/text-service"
import { textService } from "../../../services/services"

// Assets
import expert from "../../../assets/images/klinisk_ekspertise_lade_tannhelsesenter.webp"
import moderneLokaler from "../../../assets/images/moderne_lokaler_lade_tannhelsesenter.webp"

const ARTICLE_TEXTS = ["klinisk-ekspertise", "moderne-teknologi"]

const Articles = ({ className }: { className?: string }) => {
  const [infoTexts, setInfoTexts] = useState<InfoTextMap | null>(null)

  useEffect(() => {
    textService.getTexts(ARTICLE_TEXTS).then((texts) => {
      setInfoTexts(texts)
    })
  }, [])

  return (
    <div
      className={`px-2 py-2 lg:px-32 lg:py-24 ${className}`}
      style={{ backgroundColor: "#aec5c1" }}
    >
      <div className="p-4 lg:mb-24 grid lg:grid-cols-2 gap-0 lg:gap-8">
        <div className="pb-4 lg:p-0">
          <h2 className="text-4xl font-bold mb-4 lg:mb-8">
            <span className="text-primary-accent">Klinisk</span> ekspertise
          </h2>
          {infoTexts ? (
            <div
              className="mb-0 lg:mb-8 texl-lg lg:text-2xl"
              dangerouslySetInnerHTML={{
                __html: infoTexts["klinisk-ekspertise"].content,
              }}
            />
          ) : (
            <div className="mb-0 lg:mb-8 texl-lg lg:text-2xl">
              Våre tannleger er opptatte av å holde seg faglig oppdatert og har
              vært på flere kurs både innland og utland. Dette for å sikre høy
              kvalitet og omsorg i arbeidet vi gjør. Det innbærer ikke bare
              grundig kunskap om tenner og tannsykdommer, men også praktiske
              ferdigheter og erfaring til å utføre forskjellige type behandling.
            </div>
          )}
        </div>
        <div className="center-content">
          <div className="w-full flex flex-col items-center justify-center h-[32rem] overflow-hidden rounded-lg lg:rounded-none lg:rounded-tr-[4rem]">
            <img
              src={expert}
              alt="Ekspertise Lade Tannhelsesenter"
              className="w-full object-cover mt-0 xl:mt-64 rounded-lg lg:rounded-none lg:rounded-tr-[4rem]"
            />
          </div>
        </div>
      </div>
      <div className="p-4 mt-8 mb-4 grid lg:grid-cols-2 gap-8">
        <div className="pb-4 lg:p-0">
          <h2 className="text-4xl font-bold mb-4 lg:mb-8">
            <span className="text-primary-accent">Moderne</span> lokaler
          </h2>
          {infoTexts ? (
            <div
              className="mb-0 lg:mb-8 texl-lg lg:text-2xl"
              dangerouslySetInnerHTML={{
                __html: infoTexts["moderne-teknologi"].content,
              }}
            />
          ) : (
            <div className="mb-0 lg:mb-8 texl-lg lg:text-2xl">
              Vi har investert i moderne utstyr for å gjøre behandlingen så
              behagelig som mulig. The Wand anestesi som setter bedøvelse nesten
              smertefritt, intraoral scanner som tar avtrykk av munnen din uten
              avtrykksmasse og opg røntgen som tar røntgen bilde av tennene dine
              uten å ha en film i munnen din. Klinikken har store vindusflater
              for å slippe inn mye naturlig lys og er moderne utformet med god
              ventilasjon.
            </div>
          )}
        </div>
        <img
          src={moderneLokaler}
          alt="Fotona Lade Tannhelsesenter"
          className="rounded-lg lg:rounded-none lg:rounded-bl-[4rem]"
        />
      </div>
    </div>
  )
}

export default Articles
