import { FormEvent } from "react"
import { ChatUser } from "../chat"

type EditChatUserProps = {
  chatUser: ChatUser | null
  onSave: (chatUser: ChatUser) => void
}

const EditChatUser = ({ chatUser, onSave }: EditChatUserProps) => {
  const saveChatUser = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const email = document.getElementById("chat-email") as HTMLInputElement
    const phone = document.getElementById("chat-phone") as HTMLInputElement
    if (!email || !phone) return
    onSave({ id: chatUser?.id, email: email.value, phone: phone.value })
  }

  return (
    <div className="w-full h-full bg-white flex flex-col items-center p-4">
      <p className="text-center text-primary-accent">
        Vennligst legg inn e-post og telefonnummer for bruk av chat
      </p>
      <form
        onSubmit={saveChatUser}
        className="w-full flex flex-col items-center"
      >
        <input
          id="chat-email"
          defaultValue={chatUser?.email}
          className="w-full mt-4 p-2 border border-primary-accent rounded"
          type="email"
          required
          placeholder="E-post"
        />
        <input
          id="chat-phone"
          defaultValue={chatUser?.phone}
          className="w-full mt-4 p-2 border border-primary-accent rounded"
          type="tel"
          required
          placeholder="Telefonnummer"
        />
        <input
          type="submit"
          className="mt-4 font-bold text-lg bg-primary-accent text-light p-2 px-4 rounded shadow cursor-pointer hover:scale-105"
          value={"Lagre"}
        />
      </form>
    </div>
  )
}

export default EditChatUser
