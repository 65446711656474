import React, { useState, useEffect, useRef, Fragment } from "react"
import Select from "react-select"
import { imageService, treatmentService } from "../../services/services"
import { Treatment } from "../../services/treatment-service"
import LoadingCover from "../base/loading-cover/loading-cover"
import { v4 as uuidv4 } from "uuid"

const AdminTreatments = () => {
  const [loading, setLoading] = useState(true)
  const [treatments, setTreatments] = useState<Treatment[]>([])
  const [editingTreatment, setEditingTreatment] = useState<Treatment | null>(
    null
  )

  const [imageUploadFile, setImageUploadFile] = useState<File | null>(null)
  const imageUploadRef = useRef<HTMLInputElement>(null)

  // Mounted
  useEffect(() => {
    treatmentService.getTreatmentList().then((treatments) => {
      setTreatments(treatments)
      setLoading(false)
    })
  }, [])

  function createNewTreatment() {
    const defaultTreatment: Treatment = {
      id: uuidv4(),
      name: "",
      description: "",
      price: 0,
      priceNote: "",
      imageUrl: "",
    }
    setEditingTreatment(defaultTreatment)
  }

  async function saveTreatment() {
    if (!editingTreatment) return
    setLoading(true)

    if (imageUploadFile) {
      const imageUrl = await imageService.uploadImageFromFile(imageUploadFile)
      if (imageUrl) editingTreatment.imageUrl = imageUrl
      setImageUploadFile(null)
    }

    const ok = await treatmentService.updateTreatment(editingTreatment)

    if (ok) {
      if (!treatments.map((t) => t.id).includes(editingTreatment.id)) {
        setTreatments([...treatments, editingTreatment])
        return setLoading(false)
      }

      setTreatments(
        Object.values(treatments).map((treatment: Treatment) => {
          if (treatment.id === editingTreatment.id) return editingTreatment
          return treatment
        })
      )
    }

    setLoading(false)
  }

  if (loading) return <LoadingCover />

  return (
    <div className="p-2 md:p-4">
      <div className="mb-4">
        <h1 className="text-2xl text-primary-accent">Behandlinger</h1>
      </div>
      <button
        className="text-primary-accent font-bold px-4 py-2 border-2 rounded border-primary-accent mb-4"
        onClick={createNewTreatment}
      >
        Ny behandling
      </button>
      <div>
        <Select
          options={
            Object.values(treatments).map((treatment: Treatment) => {
              return { label: treatment.name, value: treatment }
            }) as any
          }
          onChange={(option) => {
            if (!option) return
            setEditingTreatment(option.value as any)
          }}
          value={
            editingTreatment
              ? { label: editingTreatment.name, value: editingTreatment }
              : null
          }
          placeholder="Velg behandling å redigerer"
        />
      </div>
      {editingTreatment && (
        <Fragment>
          <div className="mt-4 pt-4 border-t border-gray-400">
            <div>
              <label className="text-primary-accent font-bold block text-lg mr-2">
                Navn
              </label>
              <input
                type="text"
                className="w-full max-w-[24rem] border-2 border-primary focus:border-primary-accent rounded-lg px-2 py-1 text-lg"
                placeholder="Navn på behandlingen"
                value={editingTreatment.name}
                onChange={(e) =>
                  setEditingTreatment({
                    ...editingTreatment,
                    name: e.target.value,
                  })
                }
              />
            </div>
            <div className="mt-4">
              <label className="text-primary-accent font-bold block text-lg mr-2">
                Beskrivelse
              </label>
              <textarea
                className="w-full max-w-[48rem] border-2 border-primary focus:border-primary-accent rounded-lg px-2 py-1 text-lg"
                rows={10}
                placeholder="Beskrivelse av behandlingen"
                value={editingTreatment.description}
                onChange={(e) =>
                  setEditingTreatment({
                    ...editingTreatment,
                    description: e.target.value,
                  })
                }
              />
            </div>
            <div className="mt-4">
              <label className="text-primary-accent font-bold block text-lg mr-2">
                Pris
              </label>
              <div>
                <button
                  className={`${
                    editingTreatment.price === null &&
                    "!bg-primary-accent !text-light"
                  } min-w-[5rem] px-4 py-2 border-2 border-primary rounded-lg text-primary-accent font-bold`}
                  onClick={() =>
                    setEditingTreatment({ ...editingTreatment, price: null })
                  }
                >
                  Nei
                </button>
                <button
                  className={`${
                    editingTreatment.price !== null &&
                    "!bg-primary-accent !text-light"
                  } min-w-[5rem] px-4 py-2 border-2 border-primary rounded-lg text-primary-accent font-bold ml-2`}
                  onClick={() =>
                    setEditingTreatment({ ...editingTreatment, price: 0 })
                  }
                >
                  Ja
                </button>
                {editingTreatment.price != null && (
                  <input
                    type="number"
                    className="block mt-2 border-2 border-primary focus:border-primary-accent rounded-lg px-2 py-1 text-lg"
                    placeholder="Pris"
                    value={editingTreatment.price || 0}
                    onChange={(e) =>
                      setEditingTreatment({
                        ...editingTreatment,
                        price: Number(e.target.value),
                      })
                    }
                  />
                )}
              </div>
            </div>
            <div className="mt-4">
              <label className="text-primary-accent font-bold block text-lg mr-2">
                Prisbeskrivelse
              </label>
              <input
                type="text"
                className="w-full max-w-[24rem] border-2 border-primary focus:border-primary-accent rounded-lg px-2 py-1 text-lg"
                placeholder="Navn på behandlingen"
                value={editingTreatment.priceNote || ""}
                onChange={(e) =>
                  setEditingTreatment({
                    ...editingTreatment,
                    priceNote: e.target.value,
                  })
                }
              />
            </div>
            <div>
              <label className="text-primary-accent font-bold block text-lg mr-2 mt-4">
                Bilde
              </label>
              <button
                className={`min-w-[5rem] px-4 py-2 border-2 border-primary rounded-lg text-primary-accent font-bold`}
                onClick={() => imageUploadRef.current?.click()}
              >
                Endre bilde
              </button>
              <button
                className={`min-w-[5rem] px-4 py-2 border-2 border-primary rounded-lg text-primary-accent font-bold ml-2`}
                onClick={() => {
                  setEditingTreatment({ ...editingTreatment, imageUrl: "" })
                  setImageUploadFile(null)
                }}
              >
                Slett bilde
              </button>
              {imageUploadFile ? (
                <img
                  src={URL.createObjectURL(imageUploadFile as any)}
                  className="mt-4"
                  alt="behandling"
                />
              ) : (
                editingTreatment.imageUrl && (
                  <img
                    src={editingTreatment.imageUrl as string | undefined}
                    className="mt-4"
                    alt="behandling"
                  />
                )
              )}

              <input
                ref={imageUploadRef}
                className="hidden"
                type="file"
                onChange={() => {
                  if (!imageUploadRef.current?.files) return
                  const file = imageUploadRef.current?.files[0]
                  if (file) setImageUploadFile(file)
                }}
              />
            </div>
          </div>
          <button
            className="fixed bottom-0 right-0 m-4 mb-20 md:mb-4 text-light bg-primary-accent text-xl rounded-lg px-4 py-2"
            onClick={saveTreatment}
          >
            Lagre
          </button>
        </Fragment>
      )}
    </div>
  )
}

export default AdminTreatments
