/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react"
import { Chat, ChatMessage as ChatMessageType } from "../../chat/chat"
import IconButton from "../../base/icon-button/icon-button"
import { FaPaperPlane } from "react-icons/fa"
import { firestore } from "../../../App"
import {
  doc,
  addDoc,
  collection,
  where,
  query,
  setDoc,
} from "firebase/firestore"
import { useCollectionData } from "react-firebase-hooks/firestore"
import ChatMessage from "../../chat/components/chat-message"
import AdminContext from "../../../contexts/admin-context"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import LoadingCover from "../../base/loading-cover/loading-cover"

const AdminChat = () => {
  const { chatId } = useParams()

  const [visited, setVisited] = useState<{ [k: string]: boolean }>({})
  const [chatSearch, setChatSearch] = useState("")
  const { admin } = useContext(AdminContext)
  const [activeChat, setActiveChat] = useState<Chat | null>(null)

  const chatCollection = collection(firestore, "chats")
  const chatCollectionQuery = query(chatCollection)
  const [chatDocs, loadingChats, chatsError] =
    useCollectionData(chatCollectionQuery)
  const [chats, setChats] = useState<Chat[]>([])

  const messageCollection = collection(firestore, "messages")
  const messageCollectionQuery = query(
    messageCollection,
    where("chatId", "==", activeChat?.id || "")
  )
  const [messagesDocs, loadingMessages, messagesError] = useCollectionData(
    activeChat ? messageCollectionQuery : null
  )
  const [messages, setMessages] = useState<ChatMessageType[]>([])
  const [showMessages, setShowMessages] = useState(false)

  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (!chatId) {
      setVisited({})
      setActiveChat(null)
      setShowMessages(false)
      return
    }

    const isVisited = visited[chatId]

    if (!isVisited) {
      setVisited({ ...visited, [chatId]: true })
      return
    } else {
      setVisited({})
      setActiveChat(null)
      setShowMessages(false)
      navigate("/ansatt/chat", { replace: true })
    }
  }, [location])

  useEffect(() => {
    const chats = chatDocs as Chat[]
    if (!chats) return
    let sortedChats = chats.sort(
      (a, b) =>
        new Date(b.lastMessageAt || 0).getTime() -
        new Date(a.lastMessageAt || 0).getTime()
    )

    // Activate chat if chatId is in url
    if (chatId && !activeChat) {
      const chat = sortedChats.find((chat) => chat.id === chatId)
      if (chat) setActiveChat(chat)
    }

    // Filter chats
    if (chatSearch) {
      sortedChats = sortedChats.filter(
        (chat) =>
          chat.email.includes(chatSearch) || chat.phone.includes(chatSearch)
      )
    }

    setChats(sortedChats)

    // TODO: Maybe do something when new chat is created
  }, [chatDocs, chatId, chatSearch])

  useEffect(() => {
    const messages = messagesDocs as ChatMessageType[]
    if (!messages) return
    setMessages(
      messages.sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      )
    )
  }, [messagesDocs])

  useEffect(() => {
    if (!loadingMessages) {
      setTimeout(() => {
        const messageWindow = document.getElementById("admin-message-window")
        if (messageWindow) messageWindow.scrollTop = messageWindow.scrollHeight
        setShowMessages(true)
      }, 1)
    }
  }, [loadingMessages, messagesDocs])

  function activateChat(chat: Chat) {
    if (chat.id === activeChat?.id) return
    const chatInput = document.getElementById(
      "admin-chat-input"
    ) as HTMLFormElement
    if (chatInput) chatInput.value = ""

    if (visited[chat.id]) setVisited({ ...visited, [chat.id]: false })
    navigate(`/ansatt/chat/${chat.id}`)
    setShowMessages(false)
    setActiveChat(chat)
  }

  async function sendMessage(e: KeyboardEvent | MouseEvent | any) {
    e.preventDefault()
    const chatInput = document.getElementById(
      "admin-chat-input"
    ) as HTMLFormElement
    const message = chatInput.value

    if (!activeChat || !activeChat.id || !activeChat.email || !activeChat.phone)
      return console.error(
        "Cannot send message. One or more chat properties are missing:",
        activeChat
      )
    if (!message) return

    // Send message
    const date = new Date()

    const chatMessage = {
      chatId: activeChat.id,
      message: message,
      createdAt: date.toISOString(),
      employee: {
        id: admin?.id,
        email: admin?.email,
      },
    }

    chatInput.value = ""

    await addDoc(collection(firestore, "messages"), chatMessage).catch(
      (error) => {
        console.error("Error adding document: ", error)
      }
    )

    const chatUpdate: Chat = {
      id: activeChat.id,
      email: activeChat.email,
      phone: activeChat.phone,
      lastMessage: message,
      lastMessageAt: date.toISOString(),
      lastMessageFromEmployee: true,
    }

    await setDoc(doc(firestore, "chats", activeChat.id), chatUpdate)

    const messageWindow = document.getElementById("message-window")
    if (messageWindow) messageWindow.scrollTop = messageWindow.scrollHeight
  }

  return (
    <div className="flex flex-col items-center">
      <div className="w-full flex flex-row h-[calc(100vh-10rem)] md:h-[calc(100vh-6rem)]">
        <div
          className={`${
            activeChat
              ? "w-4 items-end md:w-[30vw]"
              : "flex-1 md:flex-none md:w-[30vw]"
          } z-10 h-full bg-primary-accent text-background-light flex flex-col overflow-hidden transition-all duration-300`}
        >
          <div
            className={`w-full h-20 border-b-2 border-background-light flex items-center justify-between p-4`}
          >
            <label className={`hidden lg:block text-xl font-bold mr-4`}>
              Søk:
            </label>
            <input
              type="text"
              placeholder="email eller tlf"
              value={chatSearch}
              onChange={(e) => setChatSearch(e.target.value)}
              className={`${
                activeChat && "hidden md:block"
              } flex-1 bg-background-light rounded-full px-4 py-2 text-primary-accent`}
            />
          </div>
          <div className="w-full min-w-[16rem] overflow-auto">
            {chats.map((chat, index) => {
              const isActive = chat.id === activeChat?.id
              return (
                <div
                  key={index}
                  className={`relative p-4 border-b-2 border-gray-600 cursor-pointer ${
                    isActive && "bg-background-light text-primary-accent"
                  }`}
                  onClick={() => activateChat(chat)}
                >
                  <h4 className="font-bold text-lg">{chat.email}</h4>
                  <p className="inline-block line-clamp-3">
                    {!chat.lastMessageFromEmployee && (
                      <span className="text-focus mr-1">⏺</span>
                    )}
                    {chat.lastMessage}
                  </p>
                  <p className="text-sm text-gray-400 mt-2">
                    {new Date(chat.lastMessageAt || 0).toLocaleString("no-nb", {
                      month: "short",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                    })}
                  </p>
                </div>
              )
            })}
          </div>
        </div>
        <div
          className={`${
            activeChat ? "w-0 flex-1" : "w-0 flex-shrink md:w-auto md:flex-1"
          } flex flex-col`}
        >
          <div
            id="admin-message-window"
            className="h-full flex-1 overflow-auto"
          >
            {activeChat && (
              <div className="relative h-full flex flex-col">
                <div className="fixed w-full bg-background-secondary text-primary-accent pt-3 pl-4 h-20 border-b-2 border-background-light">
                  <h4 className="font-bold text-lg">
                    Epost: {activeChat.email}
                  </h4>
                  <p className="font-bold text-lg line-clamp-3">
                    Tlf: {activeChat.phone}
                  </p>
                </div>
                <div className="flex-1 mt-20">
                  {loadingMessages && <LoadingCover />}
                  <div
                    className={`${
                      !showMessages && "invisible"
                    } h-full overflow-hidden`}
                  >
                    {!loadingMessages &&
                      !chatsError &&
                      messages?.map((message, index) => (
                        <ChatMessage
                          message={message}
                          key={index}
                          isAdmin={true}
                        />
                      ))}
                  </div>
                </div>
              </div>
            )}
          </div>

          <div
            className={`${
              !activeChat ? "overflow-hidden p-0 h-0" : "p-4"
            } flex items-center justify-between border-t-2`}
          >
            <div className="relative w-full">
              <textarea
                id="admin-chat-input"
                placeholder="Skriv her"
                className="w-full bg-background-light resize-none"
                rows={1}
                onFocus={(e) => (e.target.rows = 3)}
                onBlur={(e) => (e.target.rows = 1)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    sendMessage(e)
                  }
                }}
              />
            </div>
            <IconButton
              className="ml-4 text-primary-accent"
              onClick={(e) => sendMessage(e)}
            >
              <FaPaperPlane size={24} />
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminChat
