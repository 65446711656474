// Assets
import EhsanSmith from "../../../assets/images/dentists/ehsan_smith_lade_tannhelsesenter_tannlege.webp"
import RunaSarreTimestad from "../../../assets/images/dentists/runa_sarre_timestad_lade_tannhelsesenter_tannlege.webp"
import GunnarWettainen from "../../../assets/images/dentists/gunnar_wettainen_lade_tannhelsesenter_tannlege.webp"

const Dentists = () => {
  return (
    <div className="text-primary-accent w-full center-content flex-col p-8 lg:px-32 lg:py-24">
      <h1 className="text-4xl font-bold mb-8 lg:mb-24">Tannlegene</h1>
      <div className="grid lg:grid-cols-3 gap-8 items-start">
        <div className="h-full flex items-center flex-col justify-start">
          <div className="center-content flex-col">
            <div className="border-4 border-primary-accent rounded-full h-48 w-48 mb-8 overflow-hidden">
              <img
                src={EhsanSmith}
                alt="Ehsan Smith Tannlege Lade Tannhelsesenter"
                className="w-full -mt-4"
              />
            </div>
            <h2 className="text-xl font-bold mb-4">Ehsan Smith</h2>
          </div>
          <p className="text-center mb-8">
            Ehsan Smith er en dedikert tannlege med omfattende erfaring og
            ekspertise innen tannhelse. Han er kjent for sin omsorgsfulle
            tilnærming og evne til å skape trygge og komfortable opplevelser for
            pasientene sine.
          </p>
        </div>
        <div className="border-b border-primary-accent lg:hidden" />
        <div className="h-full flex items-center flex-col justify-start">
          <div className="center-content flex-col">
            <div className="border-4 border-primary-accent rounded-full h-48 w-48 mb-8 overflow-hidden">
              <img
                src={RunaSarreTimestad}
                alt="Runa Sarre Timestad Tannlege Lade Tannhelsesenter"
                className="w-full -mt-4"
              />
            </div>
            <h2 className="text-xl font-bold mb-4">Runa Sarre Timestad</h2>
          </div>
          <p className="text-center mb-8">
            Runa Sarre Timestad er kjent for sin profesjonalitet og dedikasjon
            til å levere omsorgsfull behandling til sine pasienter. Med en varm
            og innbydende tilnærming skaper Runa en trygg atmosfære og tar seg
            tid til å forstå pasientenes behov og bekymringer.
          </p>
        </div>
        <div className="border-b border-primary-accent lg:hidden" />
        <div className="h-full flex items-center flex-col justify-start">
          <div className="center-content flex-col">
            <div className="border-4 border-primary-accent rounded-full h-48 w-48 mb-8 overflow-hidden">
              <img
                src={GunnarWettainen}
                alt="Gunnar Wettainen Tannlege Lade Tannhelsesenter"
                className="w-full -mt-10"
              />
            </div>
            <h2 className="text-xl font-bold mb-4">Gunnar Wettainen</h2>
          </div>
          <p className="text-center mb-8">
            Gunnar arbeider delt ved den offentlige tannhelsetjenesten i
            Trøndelag og har erfaring fra privat og offentlig sektor i Sverige
            og Norge. Med verdifull erfaring fra behandling av forskjellige
            pasientgrupper etterstreber Gunnar å imøtekomme behovene til hver
            pasient ved å tilby god og forsvarlig helsehjelp.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Dentists
