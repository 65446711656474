import React from "react"
import { Link } from "react-router-dom"

const Admin = () => {
  return (
    <div className="w-full p-8">
      <h1 className="text-2xl mb-8">Ansatt side</h1>
      <div className="w-full center-content flex-col">
        <Link
          to="/ansatt/chat"
          className="w-64 text-2xl text-center border-2 border-primary-accent px-4 py-2 rounded cursor-pointer mb-2"
        >
          Chat
        </Link>
        <Link
          to="/ansatt/behandlinger"
          className="w-64 text-2xl text-center border-2 border-primary-accent px-4 py-2 rounded cursor-pointer mb-2"
        >
          Behandlinger
        </Link>
        <Link
          to="/ansatt/innstillinger"
          className="w-64 text-2xl text-center border-2 border-primary-accent px-4 py-2 rounded cursor-pointer mb-2"
        >
          Innstillinger
        </Link>
      </div>
    </div>
  )
}

export default Admin
