// Assets
import { Helmet } from "react-helmet"
import invisalign from "../../assets/images/invisalign.webp"
import OrderButton from "../base/order-button/order-button"

const Invisalign = () => {
  return (
    <div className="info w-full center-content p-8 text-dark">
      <Helmet>
        <title>Invisalign Trondheim</title>
      </Helmet>
      <div className="max-w-screen-xl">
        <div className="center-content flex-col mb-8 text-center">
          <h1>Usynlig regulering: Invisalign og andre tannreguleringstyper</h1>
          <p className={`!mb-8 mt-2`}>
            Usynlig regulering, også kjent som Invisalign eller skjult
            regulering er en tilnærming til tannregulering uten synlige
            metallkomponenter.
          </p>
          <img
            src={invisalign}
            alt="invisalign"
            className="w-full max-h-[60vh] object-cover object-center"
          />
        </div>
        <div className="mb-8">
          <h2>Hva er usynlig regulering?</h2>
          <p>
            Usynlig regulering muliggjør tannkorrigering på en diskret og
            effektiv måte, der tennene dine gradvis flyttes til sin ideelle
            plassering uten at selve reguleringen synes. Usynlig regulering
            benytter gjennomsiktige skinner fremfor tradisjonelle
            metallbraketter for å tilby en nesten usynlig
            tannreguleringsløsning. Dette har gjort behandlingen til et
            foretrukket valg blant voksne og tenåringer som søker en mindre
            synlig måte å rette tennene på.
          </p>
        </div>
        <div className="mb-8">
          <h2>Pris og kostnader ved usynlig tannregulering</h2>
          <p>
            Kostnadene for usynlig regulering kan variere basert på
            behandlingens omfang og varighet. Generelt ligger prisen mellom
            20-50 000kr. Dette på grunn av den avanserte teknologien og
            skreddersydde skinnene som brukes. Det er imidlertid viktig å
            vurdere fordelene med en diskret behandlingsmetode.
          </p>
        </div>
        <div className="mb-8">
          <h2>Fordeler med usynlig regulering</h2>
          <p>
            Den mest åpenbare fordelen med usynlig regulering er dens
            diskresjon. De klare plastskinnene gjør det mulig for brukeren å
            rette opp tennene uten at det er synlig for andre. I tillegg til
            estetikk, tilbyr denne metoden en rekke andre fordeler:
          </p>
          <h3>Komfort</h3>
          <p>
            Skinnene er skreddersydde for å passe tett og komfortabelt over
            tennene, noe som reduserer irritasjon og ubehag sammenlignet med
            tradisjonelle braketter. Hygiene Fordi skinnene kan fjernes, er det
            enklere å opprettholde god munnhygiene gjennom behandlingsperioden.
            Du kan pusse og bruke tanntråd som vanlig, noe som minimerer
            risikoen for plakkoppbygging og tannkjøttbetennelse.
          </p>
          <h3>Matvalg</h3>
          <p>
            Det er ingen restriksjoner på hva du kan spise under behandlingen,
            siden du fjerner skinnene under måltider.
          </p>
        </div>
        <div className="mb-8">
          <h2>Håndtering av spesifikke tannstillingsutfordringer</h2>
          <h3>Trangstilte tenner</h3>
          <p>
            En av de mest vanlige utfordringene som behandles med usynlig
            regulering er trangstilte tenner. Invisalign-behandlingen er
            spesielt effektiv for å skape plass der tennene er for tett sammen.
            Ved å bruke skinnene kontinuerlig, kan tennene gradvis flyttes til
            en mer ideell posisjon, noe som forbedrer både estetikk og
            funksjonalitet.
          </p>
          <h3>Dypt bitt</h3>
          <p>
            Dypt bitt, en annen vanlig tannstillingsutfordring, kan også
            korrigeres effektivt med usynlig regulering. Ved å justere hvordan
            de øvre og nedre tennene møtes, kan behandlingen bidra til å
            redusere slitasje på tennene og forbedre kjevefunksjonen.
            Kjeveortopedisk behandling For mer omfattende tannstillingsproblemer
            eller kjeveortopediske utfordringer, kan behandling med usynlig
            tannregulering kombineres med andre kjeveortopediske intervensjoner.
            Dyktige tannleger og spesialister vil utarbeide en skreddersydd
            behandlingsplan som i stor grad tar hensyn til pasientens unike
            behov.
          </p>
        </div>
        <div className="mb-8">
          <h2>Behandlingsprosessen</h2>
          <h3>Første steg: Konsultasjon og planlegging</h3>
          <p>
            Alt starter med en detaljert konsultasjon hos tannlegen, der din
            munnhelse blir nøye evaluert for å fastslå om usynlig regulering er
            egnet for deg. Ved hjelp av 3D-imaging teknologier, utarbeides en
            personlig behandlingsplan som visuelt fremstiller hvordan tennene
            dine vil flyttes til sin ideelle plassering.
          </p>
          <h3>Tilpasning av skinnene</h3>
          <p>
            Etter planlegging produseres en rekke gjennomsiktige, skreddersydde
            skinner som gradvis vil flytte tennene dine. Disse skinnene er
            designet for å utøve et mildt, men effektivt press på tennene for å
            oppnå ønsket resultat.
          </p>
          <h3>Behandlingsforløpet</h3>
          <p>
            Som pasient vil du bruke skinnene i 22 timer per dag, og bytte til
            et nytt sett omtrent hver andre uke. Regelmessige oppfølgingsbesøk
            hos tannlegen er vanlig for å overvåke fremgangen og gjøre
            justeringer i behandlingsplanen etter behov.
          </p>
        </div>
        <div className="mb-8">
          <h2>Vedlikehold</h2>
          <p>
            Vedlikehold og rengjøring av skinnene er avgjørende for å unngå
            bakterieoppbygging og for å sikre at behandlingen forblir så usynlig
            og effektiv som mulig. Regelmessig rengjøring av skinnene, samt god
            munnhygiene, er nøkkelen til en vellykket behandling.
          </p>
        </div>
        <div className="mb-8">
          <h2>Sammenligning med andre tannreguleringsmetoder</h2>
          <p>
            Selv om usynlig regulering tilbyr mange fordeler, er det viktig å
            sammenligne denne metoden med andre tannreguleringsalternativer.
            Tradisjonelle metallbraketter kan være mer egnet for komplekse
            tannstillingsproblemer, mens usynlig regulering ofte er foretrukket
            for mindre justeringer og av estetiske hensyn. Valget mellom de
            ulike metodene avhenger av ditt behov, ønsker og ikke minst
            økonomiske overveielser.
          </p>
        </div>
        <div className="mb-8">
          <h2>Vanlige problemer og løsninger</h2>
          <p>
            Tilpasning til å bruke skinnene kontinuerlig kan være en utfordring
            for noen. Det kan også oppstå mindre ubehag når tennene begynner å
            flytte seg. Disse problemene er vanligvis midlertidige og håndteres
            fint med råd og støtte fra din tannlege.
          </p>
          <h3>Overvåking og justeringer</h3>
          <p>
            Regelmessige tannlegebesøk er vanlig for å overvåke fremgangen med
            usynlig regulering. Disse besøkene gir muligheten til å gjøre
            nødvendige justeringer og sikre at behandlingen forløper som
            planlagt.
          </p>
          <h3>Etterbehandlingen</h3>
          <p>
            Når behandlingen med usynlig regulering er fullført, er det viktig å
            opprettholde resultatene. Mange pasienter vil trenge å bruke en
            retainer for å sikre at tennene forblir i sin nye posisjon.
            Retainere kan være faste eller avtagbare, og valget avhenger ofte av
            den individuelle behandlingsplanen og tannlegens anbefalinger.
            Vedlikeholdet etter behandlingen er like viktig som selve
            behandlingsperioden for å sikre langvarige resultater.
          </p>
        </div>
        <div className="mb-8">
          <h2>Bestill konsultasjon</h2>
          <p>
            Usynlig regulering, har vært et stort fremskritt i
            tannreguleringsbehandlinger ved å tilby en nesten usynlig,
            komfortabel og hygienisk måte å oppnå fine tenner på. Ved Lade
            Tannhelsesenter er vi stolte over å gi våre pasienter tilgang til
            denne moderne behandlingsformen, støttet av personlig tilnærming og
            avansert teknologi. Dersom du vurderer usynlig regulering, er det
            bare å ta kontakt med oss for en konsultasjon.
          </p>
        </div>
        <div className="center-content mt-12 mb-10">
          <OrderButton />
        </div>
      </div>
    </div>
  )
}

export default Invisalign
