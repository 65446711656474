import axios from "axios"
import AdminService from "./admin-service"
import ChatService from "./chat-service"
import TextService from "./text-service"
import ImageService from "./image-service"
import TreatmentService from "./treatment-service"

// Dev
// const BASE_URL = "http://localhost:8080"

// Prod
const BASE_URL = "https://lths-api-mj5rxsjfeq-ey.a.run.app"

const client = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
})

export const adminService = new AdminService(client)
export const chatService = new ChatService(client)
export const textService = new TextService(client)
export const imageService = new ImageService(client)
export const treatmentService = new TreatmentService(client)
