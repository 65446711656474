import { FaBook, FaCog, FaComments, FaList } from "react-icons/fa"
import { Link, useNavigate } from "react-router-dom"

const AdminBottomNav = () => {
  const navigate = useNavigate()

  const BottomNavItem = ({
    path,
    icon,
    children,
  }: {
    path: string
    icon: JSX.Element
    children?: string | JSX.Element
  }) => (
    <div
      className="center-content flex-col cursor-pointer"
      onClick={() => navigate(path)}
    >
      {icon}
      <p className="text-sm text-light font-bold">{children}</p>
    </div>
  )

  return (
    <div className="h-[4rem] md:h-0 fixed bottom-0 left-0 w-screen bg-primary-accent border-t-2 grid grid-cols-4">
      <BottomNavItem
        path="/ansatt/chat"
        icon={<FaComments className="text-xl text-light" />}
      >
        Chat
      </BottomNavItem>
      <BottomNavItem
        path="/ansatt/tekster"
        icon={<FaBook className="text-xl text-light" />}
      >
        Tekster
      </BottomNavItem>
      <BottomNavItem
        path="/ansatt/behandlinger"
        icon={<FaList className="text-xl text-light" />}
      >
        behandlinger
      </BottomNavItem>
      <BottomNavItem
        path="/ansatt/innstillinger"
        icon={<FaCog className="text-xl text-light" />}
      >
        Innstillering
      </BottomNavItem>
    </div>
  )
}

export default AdminBottomNav
