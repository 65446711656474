export type Review = {
  name: string
  rating: number
  review: string
  link: string
}

export const reviews: Review[] = [
  {
    name: "Mathias Bakken",
    rating: 5,
    review:
      "Jeg anbefaler Lade Tannhelsesenter på det sterkeste. Tannlegen min, Ehsan, var utrolig dyktig og sørget for at jeg fikk utmerket oppfølging på tannproblemene mine. Jeg følte meg svært godt ivaretatt gjennom hele prosessen.",
    link: "https://g.co/kgs/SmAs91n",
  },
  {
    name: "Nora Lilly Myrland",
    rating: 5,
    review:
      "Veldig dyktig og imøtekommende. For en med tannlegeskrekk så er det betryggende å komme til en tannlege der de faktisk har tenkt på hvordan utformingen av lokalet påvirker opplevelsen av besøket. Åpne og luftige lokaler med veldig hyggelige folk. Anbefales på det sterkeste.",
    link: "https://g.co/kgs/c1FsXai",
  },
  {
    name: "Anne Elisabeth Olufsen",
    rating: 5,
    review:
      "Jeg følte meg veldig godt tatt vare på til tross for tannlegeskrekk fra barndommen. Jeg fikk god informasjon og gode anbefalinger samt info om priser. Tannlegen var veldig vennlig og sympatisk. Vil absolutt anbefale Lade Tannhelsesenter.",
    link: "https://g.co/kgs/QVH2ey5",
  },
  {
    name: "Henrik Syrstad Moen",
    rating: 5,
    review:
      "En veldig god opplevelse. Bra fokus på å gjøre behandlingen mest mulig behagelig. Informativ og grundig gjennomgang av behandling med tid for spørsmål i forkant og etterkant av gjennomførelsen. Behandlingen var effektiv, skånsom og kommunikasjonen var god underveis. Veldig fornøyd.",
    link: "https://g.co/kgs/58HbvmT",
  },
  {
    name: "Mari Johnsen",
    rating: 5,
    review:
      "Ehsan er en dyktig og trivelig tannlege som jeg anbefaler på det sterkeste. Følte meg veldig ivaretatt under hele besøket, og vil definitivt anbefale han videre.",
    link: "https://g.co/kgs/F2DTDVA",
  },
  {
    name: "Enya Sofie Blindheim",
    rating: 5,
    review:
      "Tidenes beste opplevelse hos tannlegen! Hadde ordentlig problem med en visdomstann som måtte trekkes, og fikk raskt time. Følte meg godt tatt vare på, og selv med sprøyteskrekk så hadde jeg tilnærmet hvilepuls når jeg fikk bedøvelsesprøytene. Det sier nok det meste om hele opplevelsen. Anbefales på det varmeste ✨",
    link: "https://g.co/kgs/br5XKiM",
  },
  {
    name: "Marie Witsø Jacobsen",
    rating: 5,
    review:
      "Første besøk hos Runa, og det var en utrolig fin opplevelse. Hyggelig, jordnær, forståelig og blid! Rent og nytt lokale. Kan anbefales på det sterkeste!",
    link: "https://g.co/kgs/ww5ufwF",
  },
  {
    name: "Viljar Horn",
    rating: 5,
    review:
      "For et fantastisk team på Lade Tannhelsesenter👍 Enorm oppfølging og knallgod gjennomføring på både enkel tannsjekk og fjerning av visdomstenner. Både trekking og uken etter har gått smertefritt. Forklarer grundig hva de mener burde gjøres og hva kostnaden vil bli. Flotte lokaler og profesjonell gjeng. Anbefales på det sterkeste.",
    link: "https://g.co/kgs/1y16DqR",
  },
  {
    name: "Harald Skei",
    rating: 5,
    review:
      "Fin, moderne og hyggelig tannklinikk. Ehsan Smith, som jeg går hos, er en meget dyktig tannlege. Nøye og samvittighetsfull. Tar godt vare på sine pasienter. Flink til å finne ut hva som er problemet og å finne konstruktive løsninger. Han er veldig åpen og ærlig. Han er også veldig hyggelig som person. Anbefales på det sterkeste",
    link: "https://g.co/kgs/uhtHKn5",
  },
  {
    name: "Rebecka Waldum",
    rating: 5,
    review:
      "Veldig fornøyd. Var ganske nervøs, men fikk en veldig god opplevelse, selv om jeg nå har to tenner færre. Kommer tilbake hit om det er noe mer. Følte meg godt ivaretatt. Veldig imponert og fornøyd som sagt!",
    link: "https://g.co/kgs/QmCH5yZ",
  },
]
