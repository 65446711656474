import React from "react"

// Components
import TreatmentPrice from "./components/treatment-price"

// Constants
import { treatments } from "../../constants/constants"

const Prices = () => {
  return (
    <div className="center-content flex-col p-4 mt-16">
      <div className="w-full max-w-screen-lg mb-8">
        <h1 className="text-3xl text-primary-accent">Priser</h1>
        <div className="grid grid-cols-1 gap-4 mt-8 mb-32">
          {treatments.map((treatment, index) => (
            <TreatmentPrice key={index} treatment={treatment} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Prices
