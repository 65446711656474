// Assets
import { Helmet } from "react-helmet"
import teeth from "../../assets/images/teeth.webp"
import OrderButton from "../base/order-button/order-button"

const ToothExtraction = () => {
  return (
    <div className="info w-full center-content p-8 text-dark">
      <Helmet>
        <title>Tanntrekking Trondheim</title>
      </Helmet>
      <div className="max-w-screen-xl">
        <div className="center-content flex-col mb-8 text-center">
          <h1>Tanntrekking Trondheim - hvordan gå frem</h1>
          <p className={`!mb-8 mt-2`}>
            Fjerning av tenner er en vanlig behandling som forhindrer skader og
            smerter ved uønsket tanntilstand.
          </p>
          <img
            src={teeth}
            alt="tanntrekking"
            className="w-full max-h-[60vh] object-cover object-center"
          />
        </div>
        <div className="mb-8">
          <h2>Å forstå nødvendigheten av å trekke visdomstann</h2>
          <p>
            Å trekke visdomstann er ofte den vanligste formen for tanntrekking -
            dette kan være nødvendig når det er lite plass bakerst i munnen, og
            man opplever smerter eller problemer med visdomstennene.
            Visdomstenner som vokser inn i tannkjøttet eller kjevebenet på en
            måte som gjør dem vanskelige å rengjøre, kan føre til opphopning av
            bakterier og utvikling av infeksjoner i tannkjøttet rundt
            visdomstannen. Disse utfordringene krever ofte at man oppsøker
            tannlege for å få en vurdering av om visdomstannen bør trekkes for å
            lindre ubehaget og forebygge ytterligere komplikasjoner.
          </p>
          <h3>Trekke visdomstann pris Trondheim</h3>
          <p>
            Å forstå priser for å trekke visdomstann i Trondheim er viktig for
            pasienter som vurderer denne behandlingen. På Lade Tannhelsesenter
            er vi opptatt av å gi klar informasjon om de forventede utgiftene
            for å sikre at våre pasienter er godt informert. Det er viktig å ha
            en åpen dialog med tannlegen om de forventede kostnadene for å unngå
            overraskelser.
          </p>
        </div>
        <div className="mb-8">
          <h2>Enkel tanntrekking vs. Kirurgisk tanntrekking</h2>
          <p>
            Når det gjelder å fjerne tenner, finnes det hovedsakelig to metoder:
            enkel tanntrekking og kirurgisk tanntrekking. Valget mellom disse
            metodene avhenger av flere faktorer, inkludert tannens posisjon og
            tilstand.
          </p>
          <h3>Enkel tanntrekking</h3>
          <p>
            Enkel tanntrekking er prosessen med å fjerne en tann som er fullt
            synlig i munnen uten behov for kirurgiske inngrep. Dette er ofte
            tilfelle når en tann har blitt skadet av karies (hull i tennene)
            eller periodontal sykdom (tannkjøttsykdom) til det punktet hvor den
            ikke kan reddes. Prosedyren utføres vanligvis under lokalbedøvelse,
            og tannlegen bruker spesialinstrumenter for å løsne og fjerne tannen
            forsiktig fra tannkjøttet og kjevebenet. Etter en enkel tanntrekking
            kan pasienten oppleve milde til moderate smerter, som vanligvis kan
            håndteres med smertestillende medikamenter anbefalt av tannlegen.
            Det er viktig å følge etterbehandlingsinstruksjonene nøye for å
            fremme rask heling og forhindre komplikasjoner.
          </p>
          <h3>Kirurgisk tanntrekking</h3>
          <p>
            Kirurgisk tanntrekking er en mer kompleks prosedyre som kreves når
            en tann ikke lett kan fjernes gjennom en enkel tanntrekking. Dette
            kan være tilfelle for visdomstenner som ikke har brutt gjennom
            tannkjøttet fullstendig, eller for tenner som er sterkt ødelagt
            under tannkjøttlinjen. Under en kirurgisk tanntrekking vil tannlegen
            eller en oral kirurg gjøre et snitt i tannkjøttet for å eksponere
            tannen og kjevebenet. I noen tilfeller må deler av kjevebenet
            fjernes, eller tannen må deles opp i mindre stykker for å lette
            fjerningen. Kirurgisk tanntrekking utføres under lokalbedøvelse, men
            sedasjon kan også tilbys for å hjelpe pasienter med å slappe av
            under prosedyren. Etterbehandling etter en kirurgisk tanntrekking er
            avgjørende for helingsprosessen og krever nøye overholdelse av
            tannlegens anbefalinger.
          </p>
        </div>
        <div className="mb-8">
          <h2>Den første tiden etter tanntrekking</h2>
          <p>
            De første dagene etter å ha trukket en tann er det vanlig å oppleve
            smerter og ubehag. Pasienter blir rådet til å ta godt vare på
            området rundt den fjernede tannen, inkludert å skylle munnen
            forsiktig og ta smertestillende medisiner som anbefalt av tannlegen.
            Disse forholdsreglene bidrar til å fremskynde helingsprosessen og
            redusere risikoen for infeksjon i tannkjøttet rundt tannen.
          </p>
          <h3>Symptomer på betennelse etter tanntrekking</h3>
          <p>
            Det er viktig å være oppmerksom på symptomer på betennelse etter
            tanntrekking, som for eksempel vedvarende sterke smerter, hevelse
            eller økt temperatur i området rundt den trukne tannen. Ved slike
            symptomer er det avgjørende å ta kontakt med tannlegen for videre
            undersøkelse og behandling.
          </p>
        </div>
        <div className="mb-8">
          <h2>Tannlege med spesialkompetanse på tannlegeskrekk i Trondheim</h2>
          <p>
            For pasienter som føler ubehag ved tanken på tannbehandling, er
            valget av tannlege spesielt viktig. Lade Tannhelsesenter har
            spesialisert seg på å håndtere pasienter med odontofobi
            (tannlegeskrekk), og vi har eget behandlingsrom for å skape en trygg
            atmosfære for alle våre pasienter.
          </p>
          <h3>Viktigheten av etterbehandling</h3>
          <p>
            Riktig etterbehandling er avgjørende for å sikre en god
            helingsprosess og forebygge infeksjoner i tannkjøttet. Dette
            inkluderer regelmessig pussing av tennene og å skylle munnen for å
            holde området rent, spesielt rundt tannen som ble trukket.
          </p>
        </div>
        <div className="mb-8">
          <h2>Hvordan bestille time for tanntrekking i Trondheim</h2>
          <p>
            Hvis du har problemer med visdomstennene eller opplever symptomer
            som tyder på at en tann bør trekkes, er det viktig å bestille time
            hos en tannlege. Vårt team er spesielt utstyrt for å støtte personer
            med bekymringer eller frykt for tannbehandling, og vi er her for å
            veilede deg gjennom alle trinnene i behandlingen.
          </p>
        </div>

        <div className="center-content mt-12 mb-10">
          <OrderButton />
        </div>
      </div>
    </div>
  )
}

export default ToothExtraction
