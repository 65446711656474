import { FaMinus, FaPlus } from "react-icons/fa"
import { useEffect, useRef, useState } from "react"
import { Treatment as TreatmentType } from "../../../services/treatment-service"

type TreatmentProps = {
  treatment: TreatmentType
  defaultOpen?: boolean
}

const Treatment = ({ treatment, defaultOpen = false }: TreatmentProps) => {
  const [isOpen, setIsOpen] = useState(defaultOpen)

  const treatmentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (defaultOpen) {
      const treatmentElement = document.getElementById(treatment.id)
      if (treatmentElement) {
        treatmentElement.scrollIntoView({
          block: "center",
        })
      }
    }
  }, [])

  return (
    <div id={treatment.id} className="min-w-0 min-h-0">
      <div
        className="bg-primary-accent text-gray-100 flex flex-row justify-between items-center p-4 cursor-pointer shadow"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h2 className="text-xl">{treatment.name}</h2>
        <div className="flex flex-row items-center">
          {treatment.priceNote || treatment.price ? (
            <span className="mr-2">
              {treatment.priceNote || `${treatment.price} NOK`}
            </span>
          ) : (
            ""
          )}
          {isOpen ? <FaMinus size={20} /> : <FaPlus size={20} />}
        </div>
      </div>
      <div
        className="transition-all duration-500 ease-in-out overflow-hidden shadow-lg"
        style={{ height: isOpen ? treatmentRef.current?.scrollHeight : 0 }}
      >
        <div ref={treatmentRef} className="p-4">
          <style>
            {`
              a {
                color: #5c6ac4;
                text-decoration: underline;

                &:hover {
                  color: #434190;
                }
              }
            `}
          </style>
          <div
            className="whitespace-pre-wrap"
            dangerouslySetInnerHTML={{ __html: treatment.description }}
          />
          {treatment.imageUrl && (
            <div className="w-full center-content mt-4">
              <img
                src={treatment.imageUrl}
                alt="behandlingsgrafikk"
                className="max-h-[12rem]"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Treatment
