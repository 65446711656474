import { useState, useEffect, useContext } from "react"
import Modal from "../base/modal/modal"
import { getCookie, setCookie } from "../../utils/cookie"
import Toggle from "react-toggle"
import { analytics } from "../../App"
import AppContext from "../../contexts/app-context"

enum ConsentType {
  ANALYSIS = "analysisConsent",
  MARKETING = "marketingConsent",
}

type Consent = {
  [key in ConsentType]: boolean
}

const CookieBanner = () => {
  const [analysisConsent, setAnalysisConsent] = useState<boolean>(false)
  const [marketingConsent, setMarketingConsent] = useState<boolean>(false)

  const {
    showConsentBanner,
    setShowConsentBanner,
    showConsentSettings,
    setShowConsentSettings,
  } = useContext(AppContext)

  useEffect(() => {
    const _analysisConsent = getCookie("analysisConsent")
    const _marketingConsent = getCookie("marketingConsent")
    setAnalysisConsent(_analysisConsent === "true")
    setMarketingConsent(_marketingConsent === "true")
  }, [])

  function saveConsent(consent: Consent) {
    analytics.app.automaticDataCollectionEnabled = consent.analysisConsent
    setCookie("analysisConsent", consent.analysisConsent.toString(), 334) // 11 months
    setCookie("marketingConsent", consent.marketingConsent.toString(), 334) // 11 months
    setShowConsentBanner(false)
    setShowConsentSettings(false)
  }

  return (
    <div>
      <Modal show={showConsentSettings}>
        <div className="lg:px-2 min-w-[50vw] max-w-[90vw] lg:max-w-[50vw]">
          <h1 className="text-xl font-bold text-primary-accent mb-2">
            Cookies
          </h1>
          <p className="mb-2">
            Denne nettsiden bruker informasjonskapsler. Noen av disse
            informasjonskapselene er nødvendig for at nettsiden skal fungere. De
            nødvendige informasjonskapselene kan ikke slås av. I tillegg samler
            vi informasjon om din bruk av nettsiden slik at vi kan forbedre
            opplevelsen din. Alt av samlet data, utenom fra chat funksjonen, er
            anonym. Informasjonskapsler varer i elleve måneder. Du kan endre
            samtykke angående din data når som helst.
          </p>
          <a
            href="/cookies"
            target="_blank"
            rel="noreferrer"
            className="mb-2 bg-primary-accent text-light rounded px-2 py-1"
          >
            Du finner cookie-erklæring vår her
          </a>
          <div className="p-4 bg-gray-300 mb-2 mt-4">
            <h2 className="text-lg font-bold text-primary-accent mb-1">
              Nødvendige informasjonskapsler
            </h2>
            <p className="">
              Disse informasjonskapslene er nødvendig for at nettsiden skal
              fungere. De nødvendige informasjonskapselene kan ikke slås av.
            </p>
          </div>
          <div className="p-4 bg-gray-300 mb-2">
            <h2 className="text-lg font-bold text-primary-accent mb-1">
              Analyse
            </h2>
            <div>
              <p className="">
                Vi bruker analyse informasjonskapsler for å samle informasjon om
                din bruk av nettsiden slik at vi kan forbedre opplevelsen din.
                Alt av samlet data, utenom fra chat funksjonen, er anonym.
              </p>
              <div className="flex justify-end items-center">
                <label className="flex items-center">
                  <span className="mr-2">Godta</span>
                  <Toggle
                    checked={Boolean(analysisConsent)}
                    onChange={(e) => setAnalysisConsent(e.target.checked)}
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="p-4 bg-gray-300">
            <h2 className="text-lg font-bold text-primary-accent mb-1">
              Markedsføring
            </h2>
            <div>
              <p className="">
                Vi bruker markedsførings informasjonskapsler til å lage
                markedsføringskampanjer og reklameannonser.
              </p>
              <div className="flex justify-end items-center">
                <label className="flex items-center">
                  <span className="mr-2">Godta</span>
                  <Toggle
                    checked={Boolean(marketingConsent)}
                    onChange={(e) => setMarketingConsent(e.target.checked)}
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="flex justify-end mt-4">
            <div className="w-full flex justify-around mt-4 lg:w-auto lg:mt-0 lg:justify-center lg:ml-4">
              <button
                className="rounded px-8 py-1 border-2 border-nature-light bg-success text-light hover:bg-nature-light"
                onClick={() =>
                  saveConsent({ analysisConsent, marketingConsent })
                }
              >
                Lagre
              </button>
            </div>
          </div>
        </div>
      </Modal>
      {showConsentBanner && (
        <div className="fixed bottom-0 z-50 w-full bg-light text-primary-accent text-lg p-2 shadow border-t-2 border-primary-accent flex items-center flex-col lg:flex-row">
          <p className="flex-1">
            Vi bruker informasjonskapsler/cookies. Ved å fortsette å bruke
            nettsiden vår godtar du dette. Du kan endre samtykke når som helst
            (meny).
          </p>
          <div className="w-full flex justify-around mt-4 lg:w-auto lg:mt-0 lg:justify-center lg:ml-4">
            <button
              className="rounded px-2 py-1 border-2 border-gray-400 lg:mr-4"
              onClick={() => {
                setShowConsentSettings(true)
                setShowConsentBanner(false)
              }}
            >
              Instillinger
            </button>

            <button
              className="rounded px-8 py-1 border-2 border-primary-accent bg-focus text-primary-accent hover:bg-nature-light"
              onClick={() =>
                saveConsent({
                  [ConsentType.MARKETING]: true,
                  [ConsentType.ANALYSIS]: true,
                })
              }
            >
              Godta
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default CookieBanner
