import { FaEnvelope, FaPhone } from "react-icons/fa"

const Jobs = () => {
  return (
    <div className="center-content flex-col px-2 md:pt-8">
      <div className="max-w-screen-lg pb-8">
        <div>
          <h2 className="text-xl font-bold mb-6">
            Lade Tannhelsesenter søker nå etter en engasjert, positiv og
            serviceinnstilt tannhelsesekretær i 80-100% stilling
          </h2>
        </div>
        <div className="text-lg mb-8">
          <p className="mb-4">
            Er du vår nye tannhelsesekretær? Vår første prioritet er at
            pasienten skal bli godt tatt vare på og leter derfor etter en
            omsorgsfull, nøye og kvalitetsbevisst tannhelsesekretetær.
          </p>
        </div>
        <div className="mb-8">
          <h2 className="text-2xl font-bold mb-2 text-primary-accent">
            Vi kan tilby deg
          </h2>
          <ul className="list-disc list-inside">
            <li>Kurs og opplæring</li>
            <li>Godt arbeidsmiljø</li>
            <li>Konkurransedyktige betingelser</li>
            <li>Varierte arbeidsdager</li>
            <li>
              Godt faglig miljø i en voksende tannklinikk med mange muligheter
            </li>
            <li>Moderne og velutstyrt klinikk</li>
          </ul>
        </div>
        <div className="mb-8">
          <h2 className="text-2xl font-bold mb-2 text-primary-accent">
            Arbeidsoppgaver
          </h2>
          <ul className="list-disc list-inside">
            <li>Assistere tannlege/tannpleier</li>
            <li>Rengjøring og klargjøring av behandlingsrom</li>
            <li>Sterilisering av utstyr</li>
            <li>Besvare henvendelser fra pasienter</li>
            <li>Administrasjonsoppgaver for tannlegen</li>
            <li>Pasientkontakt</li>
            <li>Bistå resepsjon</li>
          </ul>
        </div>
        <div className="mb-8">
          <h2 className="text-2xl font-bold mb-2 text-primary-accent">
            Vi ønsker at du
          </h2>
          <ul className="list-disc list-inside">
            <li>Har pasienten i fokus og yter god kundeservice</li>
            <li>Trives i høyt tempo</li>
            <li>Er lærevillig</li>
            <li>Bidrar positivt til arbeidsmiljøet</li>
            <li>Er fleksibel tilpasningsdyktig</li>
            <li>Er autorisert tannhelsesekretær eller relevant erfaring</li>
            <li>Behersker norsk og engelsk godt, både muntlig og skriftlig</li>
          </ul>
        </div>
        <div>
          <p className="mb-1">
            Er dette noe for deg? Da ønsker vi å komme i kontakt med deg
            snarest! Send søknad og CV i dag, eller ta kontakt for en
            uforpliktende samtale med daglig leder Runa Sarre Timestad. Søknader
            vil bli vurdert fortløpende.
          </p>
          <div className="flex items-center">
            <FaPhone className="text-focus mr-2" />
            <h2 className="font-bold text-primary-accent">93 08 02 14</h2>
          </div>
          <div className="flex items-center">
            <FaEnvelope className="text-focus mr-2" />
            <h2 className="font-bold text-primary-accent">
              post@ladetannhelsesenter.no
            </h2>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Jobs
