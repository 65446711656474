import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useEffect,
  useState,
} from "react"
import { getCookie } from "../utils/cookie"

type AppContextType = {
  showConsentBanner: boolean
  setShowConsentBanner: Dispatch<SetStateAction<boolean>>
  showConsentSettings: boolean
  setShowConsentSettings: Dispatch<SetStateAction<boolean>>
}

export const AppContext = createContext<AppContextType>({
  showConsentBanner: false,
  setShowConsentBanner: () => {},
  showConsentSettings: false,
  setShowConsentSettings: () => {},
})

export const AppContextProvider = ({ children }: { children: ReactNode }) => {
  const [showConsentBanner, setShowConsentBanner] = useState(false)
  const [showConsentSettings, setShowConsentSettings] = useState(false)

  useEffect(() => {
    // Check if user has accepted cookies
    const _analysisConsent = getCookie("analysisConsent")
    const _marketingConsent = getCookie("marketingConsent")
    if (_analysisConsent === undefined || _marketingConsent === undefined) {
      setShowConsentBanner(true)
    }
  }, [])

  return (
    <AppContext.Provider
      value={{
        showConsentBanner,
        setShowConsentBanner,
        showConsentSettings,
        setShowConsentSettings,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export default AppContext
