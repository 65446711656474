import { useState } from "react"

type ChatAutoHelpProps = {
  onActivateChat: () => void
}

const ChatAutoHelp = ({ onActivateChat }: ChatAutoHelpProps) => {
  const [currentStep, setCurrentStep] = useState<number>(1)
  const [showNumber, setShowNumber] = useState<boolean>(false)

  // For now always return the same number
  const phoneNumberToCall = "73 52 00 00"

  // const phoneNumberToCall = () => {
  //   // Get current day
  //   const day = new Date().getDay()
  //   // Get current hour
  //   const hour = new Date().getHours()

  //   // If it's a weekend or before 8 or after 16
  //   if (day === 0 || day === 6 || hour < 8 || hour > 16) {
  //     return "000 00 074"
  //   }

  //   return "73 52 00 00"
  // }

  const stepMap: { [stepNumber: number]: JSX.Element } = {
    1: (
      <div className="text-center">
        <p className="text-2xl text-dark">Har du vondt?</p>
        <div className="p-8">
          <button
            className="bg-primary-accent text-light text-xl font-bold p-2 rounded w-full hover:scale-105 transition-all duration-100 mb-8"
            onClick={() => setShowNumber(true)}
          >
            Ja
          </button>
          <button
            className="bg-primary-accent text-light text-xl font-bold p-2 rounded w-full hover:scale-105 transition-all duration-100 mb-8"
            onClick={() => setCurrentStep(2)}
          >
            Nei
          </button>
        </div>
      </div>
    ),
    2: (
      <div className="text-center">
        <p className="text-xl text-dark">
          Trenger du hjelp med noen av disse tingene?
        </p>
        <div className="p-8">
          <button
            className="bg-primary-accent text-light font-bold p-2 rounded w-full hover:scale-105 transition-all duration-100 mb-2"
            onClick={() => {
              window.open("https://myanita.no/login?clinic_source=41", "_blank")
            }}
          >
            Avbestille time
          </button>
          <button
            className="bg-primary-accent text-light font-bold p-2 rounded w-full hover:scale-105 transition-all duration-100 mb-2"
            onClick={() => onActivateChat()}
          >
            Chat med oss direkte
          </button>
        </div>
      </div>
    ),
  }

  return (
    <div className="h-full bg-light p-4 overflow-auto">
      {!showNumber && stepMap[currentStep]}
      {showNumber && (
        <div className="h-full text-center center-content flex-col">
          <p className="underline decoration-danger">
            Kontakt oss på dette nummeret
          </p>
          <span className="text-2xl">{phoneNumberToCall}</span>
          <p className="mt-2 text-sm text-gray-500">
            På Lørdag og Søndag er vakttelefonen kun tilgjengelig 10:00 - 18:00
          </p>
          <button
            className="bg-light text-gray-400 border-2 border-gray-400 font-bold p-2 rounded w-full hover:scale-105 transition-all duration-100 mt-8"
            onClick={() => onActivateChat()}
          >
            Chat med oss direkte
          </button>
        </div>
      )}
    </div>
  )
}

export default ChatAutoHelp
