import { FaChevronRight } from "react-icons/fa"

type ChatButtonProps = {
  show: boolean
  setShowChat: (showChat: boolean) => void
}

const ChatButton = ({ show: showChat, setShowChat }: ChatButtonProps) => {
  return (
    <div
      className={`${
        !showChat && "hidden"
      } fixed bg-focus shadow-xl p-4 z-10 flex flex-row items-center justify-between rounded-full cursor-pointer bottom-1rem right-1rem lg:bottom-5rem lg:right-5rem`}
      onClick={() => setShowChat(true)}
    >
      <span className="text-xl font-bold mb-1 px-4 select-none">Chat</span>
      <FaChevronRight className="mr-2" />
    </div>
  )
}

export default ChatButton
