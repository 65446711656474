import React, { Fragment, useState, useEffect } from "react"
import ReactQuill from "react-quill"
import { InfoText } from "../../../services/text-service"
import Prompt from "../../base/prompt/prompt"
import { imageService, textService } from "../../../services/services"
import LoadingCover from "../../base/loading-cover/loading-cover"
import Select from "react-select"

const AdminTexts = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [showPrompt, setShowPrompt] = useState<boolean>(false)
  const [infoTexts, setInfoTexts] = useState<{ [id: string]: InfoText } | null>(
    null
  )
  const [editingText, setEditingText] = useState<InfoText | null>(null)

  const toolbarOptions: any[] = [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["link", "image"],
    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }],
    [{ direction: "rtl" }], // text direction
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ font: [] }],
    [{ align: [] }],
  ]

  // Mounted
  useEffect(() => {
    textService.getTextList().then((texts) => {
      setInfoTexts(texts)
      setLoading(false)
    })
  }, [])

  function handleOnAnswer(answer: string | boolean) {
    if (answer === false) return setShowPrompt(false)
    if (!answer || answer === "" || typeof answer != "string") return

    const newText: InfoText = {
      id: answer,
      title: "Tittel",
      content: "",
    }
    setShowPrompt(false)
    setEditingText(null)
    setTimeout(() => setEditingText(newText), 1)
  }

  async function saveText() {
    if (!editingText) return
    setLoading(true)

    const imgRegex = /<img[^>]+src="([^">]+)">/g

    let imgTag = 0
    let imageUploadPromises: Promise<any>[] = []
    let richText = editingText.content.replace(
      imgRegex,
      (match: any, url: any) => {
        imageUploadPromises.push(imageService.uploadImageFromUrl(url))
        imgTag++
        return `{{img${imgTag}}}`
      }
    )

    const imageUrls = await Promise.all(imageUploadPromises)
    imageUrls.forEach((url, i) => {
      richText = richText.replace(
        `{{img${i + 1}}}`,
        `<img src="${url}" alt="article illustration">`
      )
    })

    const updatedInfoText = {
      ...editingText,
      content: richText,
    }

    const ok = await textService.updateText(updatedInfoText)
    if (ok) {
      setEditingText(updatedInfoText)
      setInfoTexts({ ...infoTexts, [editingText.id]: updatedInfoText })
    }
    setLoading(false)
  }

  if (!infoTexts || loading) return <LoadingCover />

  return (
    <div className="p-2 md:p-4">
      <div className="mb-4">
        <h1 className="text-2xl text-primary-accent">Infotekster</h1>
      </div>
      <button
        className="text-primary-accent font-bold px-4 py-2 border-2 rounded border-primary-accent mb-4"
        onClick={() => setShowPrompt(true)}
      >
        Lag ny tekst
      </button>
      <div>
        <Select
          options={
            Object.values(infoTexts).map((text: InfoText) => {
              return { label: text.id, value: text }
            }) as any
          }
          onChange={(option) => {
            if (!option) return
            setEditingText(option.value as any)
          }}
          value={
            editingText ? { label: editingText.id, value: editingText } : null
          }
          placeholder="Velg tekst å redigerer"
        />
      </div>
      {editingText && (
        <Fragment>
          <div className="mt-4">
            <div className="text-xl flex flex-col md:flex-row ">
              <h1 className="mb-2 md:mr-4">
                Text ID:
                <span className="text-primary-accent ml-2 font-bold">
                  {editingText.id}
                </span>
              </h1>
              <div className="mb-2">
                <h2 className="mr-2 inline-block">Tittel:</h2>
                <input
                  type="text"
                  value={editingText.title}
                  onChange={(e) =>
                    setEditingText({ ...editingText, title: e.target.value })
                  }
                  className="text-primary-accent bg-light border-b-2 border-gray-400 focus:border-primary"
                />
              </div>
            </div>
            <ReactQuill
              className="editable quill-editor pb-0 md:pb-24 h-[100vh] md:h-[calc(100vh-24rem)]"
              modules={{ toolbar: toolbarOptions }}
              theme="snow"
              value={editingText.content}
              onChange={(text) =>
                setEditingText({ ...editingText, content: text })
              }
            />
          </div>
          <button
            className="fixed bottom-0 right-0 m-4 mb-20 md:mb-4 text-light bg-primary-accent text-xl rounded-lg px-4 py-2"
            onClick={saveText}
          >
            Lagre
          </button>
        </Fragment>
      )}
      <Prompt
        show={showPrompt}
        type="string"
        message="Ny text ID"
        onAnswer={handleOnAnswer}
      />
    </div>
  )
}

export default AdminTexts
