import React, { Dispatch, SetStateAction, createContext } from "react"

export type FirebaseUser = {
  id: string
  email: string
}

type AdminContextType = {
  admin: FirebaseUser | null
  setAdmin: Dispatch<SetStateAction<FirebaseUser | null>>
}

export const AdminContext = createContext<AdminContextType>({
  admin: null,
  setAdmin: (admin: any) => {},
})

export default AdminContext
