import { FaBuilding, FaClock, FaEnvelope, FaPhone } from "react-icons/fa"

// Assets
import AnitaSystems from "../../../assets/images/partners/anita-systems.webp"
import Helfo from "../../../assets/images/partners/helfo.svg"
import Plandent from "../../../assets/images/partners/plandent.webp"
import Tannlegeforeningen from "../../../assets/images/partners/tannlegeforeningen.svg"
import FacebookLogo from "../../../assets/images/social/facebook_logo.webp"
import InstagramLogo from "../../../assets/images/social/instagram_logo.webp"
import { Link } from "react-router-dom"
import { useContext } from "react"
import AppContext from "../../../contexts/app-context"

const BottomInfo = () => {
  const { setShowConsentSettings } = useContext(AppContext)

  return (
    <div className="w-full py-4 bg-primary-accent center-content flex-col">
      <div className="text-gray-100 max-w-screen-xl w-full flex flex-col items-center lg:!grid lg:grid-cols-3">
        <div className="h-full px-8 lg:border-r border-gray-100">
          <h3 className="text-2xl text-gray-100 text-center mb-8">
            Samarbeid og leverandører
          </h3>
          <div className="center-content flex-col">
            <a
              href="https://www.tannlegeforeningen.no/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={Tannlegeforeningen}
                className="h-14 w-auto mb-2"
                alt="Tannlegeforeningen"
              />
            </a>
            <a
              href="https://www.anitasystems.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={AnitaSystems}
                className="max-h-8 w-auto mb-2"
                alt="Anita Systems"
              />
            </a>
            <a
              href="https://www.helfo.no"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Helfo} className="h-8 w-auto mb-2" alt="helfo" />
            </a>
            <a
              href="https://www.lindbak.no"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="max-h-8 w-auto mb-2 text-2xl">Lindbak</p>
            </a>
            <a
              href="https://www.plandent.no"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={Plandent}
                className="max-h-8 w-auto mb-2"
                alt="Plandent"
              />
            </a>
          </div>
        </div>

        <div className="w-full border-b border-gray-100 m-8 lg:hidden" />

        <div className="h-full px-8 lg:border-r border-gray-100">
          <h3 className="text-center text-2xl mb-8">Kontakt oss</h3>
          <div className="flex flex-row items-center mb-4">
            <FaPhone className="text-focus mr-4 text" />
            <span>73 52 00 00</span>
          </div>
          <div className="flex flex-row mb-4">
            <FaBuilding className="text-focus mr-4 mt-1" />
            <div>
              <span className="block">Lade Tannhelsesenter</span>
              <span className="block">Håkon Magnussons gate 10</span>
              <span className="block">7041 Trondheim</span>
              <span className="block">Org. nr: 931 825 720</span>
            </div>
          </div>
          <div className="flex flex-row items-center mb-4">
            <FaEnvelope className="text-focus mr-4" />
            <span>post@ladetannhelsesenter.no</span>
          </div>
          <div className="flex flex-row items-center justify-center mb-4">
            <a
              href="https://www.facebook.com/profile.php?id=61551016539486"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={FacebookLogo}
                className="h-[2.8rem] w-auto rounded-lg mr-4"
                alt="facebook"
              />
            </a>
            <div className="w-2" />
            <a
              href="https://www.instagram.com/ladetannhelsesenter/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={InstagramLogo}
                className="h-[3rem] w-auto"
                alt="instagram"
              />
            </a>
          </div>
        </div>

        <div className="w-full border-b border-gray-100 m-8 lg:hidden" />

        <div className="h-full w-full px-8">
          <div className="center-content flex-row mb-8">
            <FaClock size={24} className="text-focus mr-2" />
            <h3 className="text-2xl text-gray-100">Åpningstider</h3>
          </div>
          <div className="w-full">
            <div className="w-full flex flex-row items-center justify-between border-b mb-2">
              <p className="text-xl">Mandag:</p>
              <p>08:00 - 19:00</p>
            </div>
            <div className="flex flex-row items-center justify-between border-b mb-2">
              <p className="text-xl">Tirsdag:</p>
              <p>08:00 - 19:00</p>
            </div>
            <div className="flex flex-row items-center justify-between border-b mb-2">
              <p className="text-xl">Onsdag:</p>
              <p>08:00 - 19:00</p>
            </div>
            <div className="flex flex-row items-center justify-between border-b mb-2">
              <p className="text-xl">Torsdag:</p>
              <p>08:00 - 19:00</p>
            </div>
            <div className="flex flex-row items-center justify-between border-b mb-2">
              <p className="text-xl">Fredag:</p>
              <p>08:00 - 16:00</p>
            </div>
            <div className="flex flex-row items-center justify-between border-b mb-2">
              <p className="text-xl">Lørdag:</p>
              <p>Vakttelefon</p>
            </div>
            <div className="flex flex-row items-center justify-between">
              <p className="text-xl">Søndag:</p>
              <p>Vakttelefon</p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full max-w-screen-xl flex flex-col items-center text-light border-t border-light mt-4 pt-4">
        <div>
          <Link to="/personvern" className="underline">
            Personvern
          </Link>
          {" & "}
          <button
            className="underline"
            onClick={() => setShowConsentSettings(true)}
          >
            Informasjonskapsler
          </button>
        </div>
        <p className="px-8 mt-8 mb-2">
          Nettside og System av <span className="font-bold">Erling Roll</span>
        </p>
      </div>
    </div>
  )
}

export default BottomInfo
