import { useEffect, useState } from "react"
import { Chat, ChatMessage as ChatMessageType, ChatUser } from "../chat"
import IconButton from "../../base/icon-button/icon-button"
import { FaPaperPlane } from "react-icons/fa"
import { firestore } from "../../../App"
import {
  doc,
  addDoc,
  collection,
  where,
  query,
  setDoc,
} from "firebase/firestore"
import { useCollectionData } from "react-firebase-hooks/firestore"
import ChatMessage from "./chat-message"
import { ChatConfig } from "../../../services/chat-service"

type ChatWindowProps = {
  chatUser: ChatUser
  chatConfig: ChatConfig
}

const ChatWindow = (props: ChatWindowProps) => {
  const messageCollection = collection(firestore, "messages")
  const messageCollectionQuery = query(
    messageCollection,
    where("chatId", "==", props.chatUser.id)
  )
  const [messagesDocs, loading, error] = useCollectionData(
    messageCollectionQuery
  )
  const [messages, setMessages] = useState<ChatMessageType[]>([])

  useEffect(() => {
    const messages = messagesDocs as ChatMessageType[]
    if (!messages) return
    setMessages(
      messages.sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      )
    )
    setTimeout(() => {
      const messageWindow = document.getElementById("message-window")
      if (messageWindow) messageWindow.scrollTop = messageWindow.scrollHeight
    }, 1)
  }, [messagesDocs])

  const sendMessage = async (e: KeyboardEvent | MouseEvent | any) => {
    e.preventDefault()
    const chatInput = document.getElementById("chat-input") as HTMLFormElement
    const message = chatInput.value

    if (!props.chatUser.id || !props.chatUser.email || !props.chatUser.phone)
      return console.error(
        "Cannot send message. One or more chatUser properties are missing:",
        props.chatUser
      )
    if (!message) return

    // Send message
    const date = new Date()

    const chatMessage = {
      chatId: props.chatUser.id,
      message: message,
      createdAt: date.toISOString(),
    }

    chatInput.value = ""

    await addDoc(collection(firestore, "messages"), chatMessage).catch(
      (error) => {
        console.error("Error adding document: ", error)
      }
    )

    const chatUpdate: Chat = {
      id: props.chatUser.id,
      email: props.chatUser.email,
      phone: props.chatUser.phone,
      lastMessage: message,
      lastMessageAt: date.toISOString(),
      lastMessageFromEmployee: false,
    }

    await setDoc(doc(firestore, "chats", props.chatUser.id), chatUpdate)

    const messageWindow = document.getElementById("message-window")
    if (messageWindow) messageWindow.scrollTop = messageWindow.scrollHeight

    // Auto reply
    setTimeout(() => {
      if (props.chatConfig?.autoReply && props.chatConfig?.autoReplyMessage) {
        setMessages([
          ...messages,
          chatMessage,
          {
            chatId: props.chatUser.id || "",
            message: props.chatConfig.autoReplyMessage,
            createdAt: date.toISOString(),
            employee: {
              id: "system",
              email: "system",
            },
          },
        ])
        setTimeout(() => {
          if (messageWindow)
            messageWindow.scrollTop = messageWindow.scrollHeight
        }, 100)
      }
    }, 500)
  }

  return (
    <div className="bg-white h-full flex flex-col">
      <div className="relative flex-1 border-b-2">
        <div
          id="message-window"
          className="absolute h-full w-full overflow-auto"
        >
          <ChatMessage
            message={{
              chatId: "system",
              employee: {
                id: "system",
                email: "system",
              },
              message:
                "Ved bruk av chat godtar du at vi lagrer din chat-historikk.",
              createdAt: new Date().toISOString(),
            }}
          />
          {!loading &&
            !error &&
            messages?.map((message, index) => (
              <ChatMessage message={message} key={index} />
            ))}
        </div>
      </div>
      <div className="flex items-center justify-between px-4 py-4">
        <div className="relative w-full">
          <textarea
            id="chat-input"
            placeholder="Skriv her"
            className="w-full resize-none"
            rows={1}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                sendMessage(e)
              }
            }}
          />
        </div>
        <IconButton
          className="ml-4 text-primary-accent"
          onClick={(e) => sendMessage(e)}
        >
          <FaPaperPlane size={24} />
        </IconButton>
      </div>
    </div>
  )
}

export default ChatWindow
