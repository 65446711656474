import OrderButton from "../base/order-button/order-button"

// Assets
import frontImage from "../../assets/images/lade_tannhelsesenter.webp"
import { Helmet } from "react-helmet"

const Invisalign = () => {
  return (
    <div className="info w-full center-content p-8 text-dark">
      <Helmet>
        <title>Tannlegeskrekk</title>
      </Helmet>
      <div className="max-w-screen-xl">
        <div className="center-content flex-col mb-8 text-center">
          <h1>Tannlegeskrekk: Få behandling i Trondheim</h1>
          <p className={`!mb-8 mt-2`}>
            Tannlegeskrekk, eller odontofobi, er en utbredt angst for
            tannbehandling som berører mange mennesker.
          </p>
          <img
            src={frontImage}
            alt="tannlegeskrekk"
            className="w-full max-h-[60vh] object-cover object-center"
          />
        </div>
        <div>
          <h2>Angst for tannbehandling: En vanlig fobi</h2>
          <p>
            Undersøkelser viser at et betydelig antall individer utsetter
            nødvendige tannlegebesøk på grunn av frykt. Denne frykten kan
            variere fra mild nervøsitet til sterk angst, og i noen tilfeller
            utvikle seg til psykiske lidelser. Personer som lider av
            tannlegeskrekk opplever ofte en intens frykt ved tanken på å gå til
            tannlegen, noe som kan føre til at de unngår tannbehandling helt til
            situasjonen blir uutholdelig.
          </p>
        </div>

        <div>
          <h2>Årsaker til odontofobi</h2>
          <p>
            Personen med odontofobi kan ha blitt utsatt for en traumatisk
            opplevelse hos tannlegen eller et annet sted, som har forankret
            frykten dypt i psyken. Denne frykten er ikke ubegrunnet eller
            irrasjonell; den er en reell bekymring som kan sammenlignes med for
            eksempel høydeskrekk.
          </p>
        </div>
        <div>
          <h2>Konsekvenser av å utsette tannlegebesøk</h2>
          <p>
            Å unngå tannlegebesøk kan føre til forverring av tannhelsen, som
            igjen kan nødvendiggjøre mer omfattende og kostbare behandlinger på
            sikt. For personer med odontofobi kan tanken på en vanlig
            tannbehandling virke overveldende, og derfor velger mange å ignorere
            problemet til det blir kritisk.
          </p>
        </div>
        <div>
          <h2>Møte utfordringen: Første skritt mot behandling</h2>
          <p>
            Det første møtet med tannlegen er avgjørende for pasienter med
            odontofobi. Hos oss møter du tannleger som har kurset seg på å
            behandle personer med tannlegeskrekk, og eget samtalerom dersom du
            sliter med tannlegeskrekk. Vi forstår viktigheten av å bygge en
            relasjon basert på tillit og trygghet med pasientene.
          </p>
        </div>
        <div>
          <h2>Behandlingsmetoder for odontofobi</h2>
          <p>
            Vi bruker metoder som samtaleterapi og eksponeringsterapi, og mener
            at nøkkelen er å bruke god tid til å bygge tillit mellom pasient og
            behandler. Vi ser ofte at de med stor angst klarer å motta
            behandling og at angsten gradvis avtar etter flere besøk. Vi har
            lyktes i å hjelpe mange mennesker å blitt kvitt angsten for å gå til
            tannlege.
          </p>
        </div>
        <div>
          <h2>Tilnærming under behandlingen</h2>
          <p>
            Under behandlingen, legges det stor vekt på å skape en rolig og
            trygg atmosfære. Tannlegene som behandler odontofobi hos oss bruker
            teknikker som er spesifikt rettet mot å minimere angst og ubehag.
          </p>
        </div>
        <div>
          <h2>Tannlegeskrekk Trondheim</h2>
          <p>
            For de som lider av tannlegeskrekk, er det viktig å vite at det
            finnes hjelp å få. Ved å oppsøke en tannlege som har kurset seg på å
            behandle personer med odontofobi, kan pasienter ta kontroll over sin
            tannhelse uten frykt. Her kan du lese mer om våre metoder og
            bestille time så skal vi sammen komme i mål. Å være redd for å gå
            til tannlegen er en vanlig bekymring, men det bør ikke hindre noen i
            å motta nødvendig tannbehandling.
          </p>
        </div>
        <div className="mb-8">
          <h2>Veien videre</h2>
          <p>
            Tannlegeskrekk er en alvorlig og vanlig tilstand som krever
            forståelse, tålmodighet og riktig behandling. I Trondheim er vi
            kurset i behandling av personer med odontofobi. Er du redd for å dra
            til tannlegen, ta kontakt med oss, så kan du få hjelp til å
            overkomme tannlegeskrekken. Husk, det første skrittet er ofte det
            vanskeligste, men det er også det viktigste for å overvinne frykten.
          </p>
        </div>
        <div className="center-content mt-12 mb-10">
          <OrderButton />
        </div>
      </div>
    </div>
  )
}

export default Invisalign
