import React from "react"
import PrivacyPolicyNo from "./languages/privacy-policy-no"

const PrivacyPolicy = () => {
  return (
    <div className="policy mt-32 mx-4 center-content">
      <div className="max-w-screen-xl">
        <PrivacyPolicyNo />
      </div>
    </div>
  )
}

export default PrivacyPolicy
