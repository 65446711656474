import { useState } from "react"

type PromptProps = {
  show?: boolean
  message: string
  type: "string" | "bool"
  onAnswer: (response: string | boolean) => void
}

const Prompt = (props: PromptProps) => {
  const [input, setInput] = useState<string>("")
  if (!props.show) return <></>
  return (
    <div className="fixed top-0 left-0 w-screen h-screen center-content">
      <div className="z-30 p-4 border-2 border-primary-accent rounded-lg bg-light">
        <h1 className="mb-2">{props.message}</h1>
        {props.type === "string" && (
          <input
            id="prompt-input"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            autoFocus
            type="text"
            placeholder="Skriv her"
            className="bg-light border-b-2 w-full mb-4 focus:border-primary"
            onKeyDown={(e) => {
              if (e.key === "Enter") props.onAnswer(input)
            }}
          />
        )}
        <div className="flex items-center justify-between">
          <button
            className="bg-primary-accent text-light font-bold hover:scale-105 px-4 py-2 rounded w-24 mr-2"
            onClick={() => {
              props.onAnswer(props.type === "string" ? input : true)
            }}
          >
            Ok
          </button>
          <button
            className="bg-primary-accent text-light font-bold hover:scale-105 px-4 py-2 rounded w-24"
            onClick={() => props.onAnswer(false)}
          >
            {props.type === "string" ? "Avbryt" : "Nei"}
          </button>
        </div>
      </div>

      <div className="bg-gray-500 bg-opacity-25 w-full h-full absolute backdrop-blur-sm" />
    </div>
  )
}

export default Prompt
