// Source: https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
import React, { useEffect } from "react"

type useClickAwayProps = {
  ref: React.RefObject<HTMLDivElement>
  onClickAway: () => void
}

const useClickAway = ({ ref, onClickAway }: useClickAwayProps) => {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (!ref.current || !event.target) return
      if (!ref.current.contains(event.target)) {
        onClickAway()
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [ref])
}

export default useClickAway
