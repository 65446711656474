import { AxiosInstance } from "axios"

export type InfoText = {
  id: string
  title: string
  content: string
}

export type InfoTextMap = {
  [key: string]: InfoText
}

export default class TextService {
  private client: AxiosInstance

  constructor(client: AxiosInstance) {
    this.client = client
  }

  public updateText = async (text: InfoText) => {
    const response = await this.client.post("/admin/info-text", text)
    return response.data
  }

  public getTextList = async () => {
    const response = await this.client.get("/info-texts/list")
    return response.data
  }

  public getTexts = async (ids: string[]) => {
    const response = await this.client.post("/info-texts", ids)
    return response.data
  }

  public getText = async (id: string) => {
    const response = await this.client.get(`/info-text/${id}`)
    return response.data
  }
}
