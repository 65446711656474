import Slider from "react-slick"
import "./reviews.scss"
import { reviews } from "../../../constants/reviews"
import googleIcon from "../../../assets/images/google_icon.svg"
import { FaStar } from "react-icons/fa"

const StarReview = ({ rating }: { rating: number }) => {
  return (
    <div className="flex flex-row my-2 items-center">
      {Array.from({ length: 5 }, (_, index) => (
        <FaStar
          key={index}
          className={`mr-[1px] ${
            index < rating ? "text-[#fabb05]" : "text-gray-300"
          }`}
        />
      ))}
      <span className="text-sm text-gray-400 ml-2">{rating}/5</span>
    </div>
  )
}

const Reviews = () => {
  return (
    <div id="reviews" className="w-full pt-4">
      <h2 className="text-3xl font-bold text-primary-accent text-center mb-2 mt-4">
        Våre anmeldelser
      </h2>
      <Slider
        dots={false}
        infinite={true}
        arrows={true}
        slidesToShow={3}
        autoplay={true}
        pauseOnFocus={true}
        pauseOnDotsHover={true}
        pauseOnHover={true}
        autoplaySpeed={5000}
        centerMode={true}
        responsive={[
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              arrows: false,
            },
          },
        ]}
      >
        {reviews.map((review, index) => (
          <div key={index} className="h-full w-64 p-2">
            <div className="p-4 rounded-lg flex flex-col justify-between items-start bg-[#dbebdd]">
              <div>
                <h3 className="text-lg font-bold text-primary-accent">
                  {review.name}
                </h3>
                <StarReview rating={review.rating} />
                <p className="mb-2">{review.review}</p>
              </div>
              <div className="center-content">
                <a
                  href={review.link}
                  className="text-primary-accent rounded-full"
                >
                  <div className="flex flex-row">
                    <img src={googleIcon} alt={"Google"} className="mr-4" />
                    <div>
                      <p className="text-primary-accent">Postet på</p>
                      <p className="text-[#4285F4]">Google</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default Reviews
