import { useEffect, useState } from "react"

type ModalProps = {
  show?: boolean
  onClose?: () => void
  children?: React.ReactNode
}

const Modal = ({ show, onClose, children }: ModalProps) => {
  if (!show) return <></>
  return (
    <div className="fixed z-50 top-0 left-0 w-screen h-screen flex flex-col items-center bg-dark bg-opacity-50 overflow-auto">
      <div className="z-30 p-4 m-4 border-2 border-primary-accent rounded-lg bg-light">
        {children}
      </div>
      <div className="bg-gray-500 bg-opacity-25 w-full h-full absolute backdrop-blur-sm" />
    </div>
  )
}

export default Modal
