import React from "react"
interface CloseButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode
}

const IconButton = (props: CloseButtonProps) => {
  const { children, ...rest } = props

  return <button {...rest}>{children}</button>
}

export default IconButton
