import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"

// Styles
import "./index.css"
import "./styles/hover-underline-animation.css"
import "./styles/react-toggle.css"
import "./styles/policy.scss"
import "react-quill/dist/quill.snow.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

// Set moment locale
import moment from "moment"
import "moment/locale/nb"
moment.locale("nb")

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(<App />)
