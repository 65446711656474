import Slider from "react-slick"
import "./topical.scss"

// Constants
import articles from "../../constants/articles"
import { Link } from "react-router-dom"

const TopicalCarousel = () => {
  return (
    <div
      id="topical-carousel"
      className="w-full border-[1.5rem] lg:border-[2rem] border-focus"
    >
      <Slider
        dots={true}
        infinite={true}
        arrows={true}
        slidesToShow={3}
        autoplay={true}
        pauseOnFocus={true}
        pauseOnDotsHover={true}
        pauseOnHover={true}
        autoplaySpeed={5000}
        centerMode={true}
        responsive={[
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              arrows: false,
            },
          },
        ]}
      >
        {articles.map((article, index) => (
          <div key={index} className="h-full w-64 p-2">
            <div className="h-full p-2 rounded-lg flex flex-col justify-between hover:bg-focus hover:bg-opacity-50">
              <div>
                <img
                  src={article.image}
                  alt={article.title}
                  className="w-full h-48 mb-2 object-cover rounded-lg"
                />
                <h3 className="text-lg font-bold text-primary-accent">
                  {article.title}
                </h3>
                <p className="mb-2">{article.short}</p>
              </div>
              <div className="center-content">
                <Link
                  to={`info/${article.id}`}
                  className="text-light bg-primary-accent rounded-full p-2 px-4 font-bold shadow-lg hover:bg-light hover:text-primary-accent"
                >
                  Les mer
                </Link>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default TopicalCarousel
