// Assets
import { Helmet } from "react-helmet"
import OrderButton from "../base/order-button/order-button"
import TannblekingImg from "../../assets/images/tannbleking.webp"

const Tannbleking = () => {
  return (
    <div className="info w-full center-content p-8 text-dark">
      <Helmet>
        <title>Tannbleking Trondheim</title>
      </Helmet>
      <div className="max-w-screen-xl">
        <div className="center-content flex-col mb-8 text-center">
          <h1>Tannbleking Trondheim: Trygg behandling hos tannlege</h1>
          <p className={`!mb-8 mt-2`}>
            Fjerning av misfarging for langvarig hvitere tenner og et strålende
            smil.
          </p>
          <img
            src={TannblekingImg}
            alt="tannbleking"
            className="w-full max-h-[60vh] object-cover object-center"
          />
        </div>
        <div className="mb-8">
          <p>
            Ønsker du hvitere tenner og vurderer tannbleking hos tannlege? Hos
            Lade Tannhelsesenter i Trondheim tilbyr vi tannbleking hos tannlege
            som gir langvarige resultater og et naturlig hvitt smil. Med
            skreddersydd behandling kan vi trygt hjelpe deg med å oppnå ønsket
            resultat, enten du ønsker å bleke tennene etter misfarging fra
            kaffe, te eller rødvin, eller for å få et friskere smil. Her
            forklarer vi hvordan prosessen fungerer, hva du kan forvente, og
            hvilke tannbleking-priser vi tilbyr.
          </p>
        </div>
        <div className="mb-8">
          <h2>Fordeler ved tannbleking hos tannlege</h2>
          <p>
            Tannbleking utført av tannlege gir trygghet og individuell
            tilpasning. Misfarging av tennene skjer ofte som følge av vanlige
            drikker som kaffe, te og rødvin, samt naturlig aldring. En
            profesjonell behandling hos tannlege sikrer at emaljen beskyttes og
            at du får et jevnt, naturlig resultat. Hos Lade Tannhelsesenter
            benytter vi Opalescence, et skånsomt blekemiddel som gir hvitere
            tenner uten risiko for skade. Behandlingen tilpasses hver enkelt
            pasient for å sikre et godt resultat.
          </p>
        </div>
        <div className="mb-8">
          <h2>Hvordan foregår blekingen hos tannlege?</h2>
          <p>
            Hos Lade Tannhelsesenter starter vi alltid med en grundig
            undersøkelse av tenner og tannkjøtt for å forsikre oss om at du ikke
            har hull i tennene eller andre tannproblemer. Vi tar også et avtrykk
            av tennene dine for å lage en individuelt tilpasset blekeskinne som
            sikrer jevn fargefordeling. Under behandlingen påfører tannlegen
            Opalescence-blekemiddelet i blekeskinnen, som deretter settes på
            tennene. Dette gir effektiv behandling for misfargede tenner uten
            uønskede bivirkninger. Behandlingen foregår i klinikken under oppsyn
            av en tannlege eller tannpleier, slik at vi kan tilpasse
            blekestyrken etter dine ønsker. På denne måten oppnår du et sikkert
            og godt resultat, med minimalt ubehag. De fleste opplever et
            langvarig resultat av tannbleking, og mange trenger kun én
            behandling i året for å opprettholde hvite tennene.
          </p>
        </div>
        <div className="mb-8">
          <h2>Vanlige spørsmål og priser</h2>
          <h3>Pris tannbleking tannlege</h3>
          <p>
            Hos Lade Tannhelsesenter tilbyr vi konkurransedyktige priser. Prisen
            for å bleke én kjeve er 2900 kr, mens pris for begge kjever er 4500
            kr. Disse prisene inkluderer spesialtilpasset blekeskinne og
            Opalescence-blekemiddel, samt oppfølging av erfarne tannleger. Vi
            gir deg en trygg og profesjonell behandling som gir et langvarig og
            naturlig resultat.
          </p>
          <h3>Kan man bleke tennene hjemme?</h3>
          <p>
            Ja, tannbleking hos Lade Tannhelsesenter er designet for hjemmebruk.
            Vi lager spesialtilpassede blekeskinner som brukes sammen med
            Opalescence-blekemiddel, og du utfører hele behandlingen selv
            hjemme. Med våre instruksjoner og veiledning er dette en trygg og
            effektiv metode som gir langvarige resultater. Vi sørger for at du
            har alt du trenger for å oppnå ønsket resultat, samtidig som du
            unngår risiko for ising i tennene eller sensitivitet.
          </p>
        </div>
        <div className="mb-8">
          <h2>
            Hvordan bruke blekeskinnen hjemme - Bruksanvisning for Opalescence
          </h2>
          <p>
            Når du velger å bleke tennene hjemme, er det viktig å følge
            instruksjonene nøye for å sikre et godt og trygt resultat.
            Opalescence-blekesystemet leveres med blekemiddel og en
            spesialtilpasset blekeskinne. Følg denne fremgangsmåten:
          </p>

          <ul className="list-disc pl-4 mb-4">
            <li>
              <strong>Materialer som brukes</strong>
              <ul className="list-decimal pl-8 mb-4">
                <li>
                  <strong>Blekeskinne:</strong> En myk skinne tilpasset dine
                  tenner.
                </li>
                <li>
                  <strong>Opalescence-blekemiddel:</strong> Inneholder 10 %
                  karbamidperoksid og finnes i forskjellige styrker avhengig av
                  behov.
                </li>
                <li>
                  <strong>Oppbevaringseske:</strong> For oppbevaring av skinnen
                  mellom behandlingene.
                </li>
              </ul>
            </li>
            <li>
              <strong>Behandlingstid</strong>
              <p className="!mb-0">Behandlingstiden tilpasses dine behov:</p>
              <ul className="list-decimal pl-8 mb-4">
                <li>
                  <strong>Nattbleking:</strong> Brukes mens du sover, i 5-10
                  timer.
                </li>
                <li>
                  <strong>Dagbleking:</strong> Brukes 1-2 ganger daglig i 1-4
                  timer per gang.
                </li>
              </ul>
            </li>
            <li>
              <strong>Instruksjoner for bruk av blekeskinne</strong>
              <ul className="list-decimal pl-8 mb-4">
                <li>Puss tennene godt før bruk.</li>
                <li>
                  Fyll skinnen med en liten mengde Opalescence-gel per tann (en
                  liten dråpe).
                </li>
                <li>Sett skinnen på plass over tennene og press forsiktig.</li>
                <li>Fjern overflødig gel fra tannkjøttet med en Q-tip.</li>
                <li>
                  Etter behandlingstiden, fjern skinnen og skyll munnen grundig.
                </li>
                <li>
                  Rengjør skinnen med kaldt vann og oppbevar den i
                  oppbevaringsesken.
                </li>
              </ul>
            </li>
            <li>
              <strong>Mulige bivirkninger</strong>
              <p className="!mb-0">
                Noen kan oppleve midlertidig sensitivitet i tennene eller
                tannkjøttet. Dersom ubehaget vedvarer, ta kontakt med oss for
                veiledning.
              </p>
            </li>
          </ul>
        </div>
        <div className="mb-8">
          <h2>Hva bør du vite før tannbleking?</h2>
          <p>
            Etter tannblekingen anbefales det å unngå mørke drikker som kaffe,
            te og rødvin i en kortere periode for å bevare fargen. Ising i
            tennene kan oppstå etter behandlingen, men tannlegen gir deg råd om
            hvordan du kan minimere dette og bevare resultatet lengst mulig. Hos
            Lade Tannhelsesenter benytter vi Opalescence-blekemiddel, som er
            spesielt utviklet for å gi minimale bivirkninger samtidig som det
            bleker tennene effektivt.
          </p>
        </div>
        <div className="mb-8">
          <h2>Hvem passer tannbleking hos tannlege for?</h2>
          <p>
            Tannbleking hos tannlege passer for de fleste voksne som har sunne
            tenner og tannkjøtt. Har du misfargede tenner og ønsker et hvitere
            smil, kan tannbleking være et godt alternativ. Mange opplever at
            tannbleking gir økt selvtillit og et friskere uttrykk. Husk at
            individuell tilpasning er viktig for å sikre et naturlig resultat,
            og behandlingen må derfor utføres etter en grundig sjekk hos
            tannlegen.
          </p>
        </div>
        <div className="mb-8">
          <h2>Tips for å bevare et godt resultat etter tannbleking</h2>
          <p>
            Effekten av tannbleking kan variere fra person til person, men de
            fleste opplever et varig og naturlig hvitt resultat. Vedlikehold av
            tennene, moderat inntak av kaffe, te og andre mørke drikker, samt
            regelmessige tannlegebesøk, vil bidra til å opprettholde resultatet.
            De fleste trenger kun en årlig behandling for å bevare hvitheten, og
            tannlegen vil gi deg råd om hvordan du kan opprettholde effekten på
            best mulig måte.
          </p>
        </div>
        <div className="mb-8">
          <h2>Tannbleking Trondheim - Hvite tenner hos Lade Tannhelsesenter</h2>
          <p>
            Hos Lade Tannhelsesenter i Trondheim får du tannbleking hos tannlege
            utført i trygge omgivelser. Med spesialtilpassede løsninger og
            langvarig effekt, gir våre erfarne tannleger deg en profesjonell
            opplevelse. Vi bruker Opalescence-blekemiddel og individuelt
            tilpassede skinner for å sikre et naturlig og hvitt smil som varer.
            Ønsker du mer informasjon om tannbleking pris eller vil du bestille
            en konsultasjon? Ta kontakt for en uforpliktende prat - vi ser frem
            til å gi deg trygg og profesjonell tannbleking!
          </p>
        </div>
        <div className="center-content mt-12 mb-10">
          <OrderButton />
        </div>
      </div>
    </div>
  )
}

export default Tannbleking
