import Rotfylling from "../../../assets/icons/rotfylling.svg"
import Implantat from "../../../assets/icons/implantat.svg"
import Krone from "../../../assets/icons/krone.svg"
import AkuttBehandling from "../../../assets/icons/akutt_behandling.svg"
import EstetiskBehandling from "../../../assets/icons/estetisk_behandling.svg"
import Karies from "../../../assets/icons/karies.svg"
import TannkjøttSykdom from "../../../assets/icons/tannkjott_sykdom.svg"
import Tannsjekk from "../../../assets/icons/tannsjekk.svg"
import { Link } from "react-router-dom"

const OurServices = ({ className }: { className?: string }) => {
  const ServiceBox = ({
    title,
    link,
    alias,
    icon,
  }: {
    title: string
    link: string
    alias?: string
    icon?: any
  }) => (
    <Link to={link}>
      <div className="flex flex-col mb-2 items-center">
        {icon ? (
          <img
            src={icon}
            alt={title}
            className="w-48 h-48 cursor-pointer hover:scale-105 -mb-4"
          />
        ) : (
          <div className="bg-primary-accent rounded-full h-32 w-32 mb-2 cursor-pointer hover:scale-105" />
        )}
        <label className="text-primary-accent text-2xl font-bold cursor-pointer hyphens-manual center-content">
          {title}
        </label>
        <label className="text-primary-accent opacity-75">{alias}</label>
      </div>
    </Link>
  )

  return (
    <div
      className={`w-full px-8 lg:px-24 center-content flex-col ${className}`}
    >
      <h1 className="text-primary-accent text-4xl font-bold md:mb-12">
        Våre tjenester
      </h1>
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-8 w-full">
        <ServiceBox
          title="Akutt behandling"
          link="/info/tannlegevakt"
          icon={AkuttBehandling}
        />
        <ServiceBox
          title="Tannhelse&shy;sjekk"
          link="/info/behandlinger/undersokelse"
          icon={Tannsjekk}
        />
        <ServiceBox
          title="Fylling"
          link="/info/behandlinger/fylling"
          icon={Karies}
        />
        <ServiceBox
          title="Rotfylling"
          link="/info/behandlinger/rotfylling"
          icon={Rotfylling}
        />
        <ServiceBox
          title="Krone/Bro"
          link="/info/behandlinger/krone"
          icon={Krone}
        />
        <ServiceBox
          title="Implantat"
          link="/info/behandlinger/implantat"
          icon={Implantat}
        />
        <ServiceBox
          title="Tannkjøtt&shy;sykdom"
          alias="Periodontitt"
          link="/info/behandlinger/tannkjottsbehandling"
          icon={TannkjøttSykdom}
        />
        <ServiceBox
          title="Estetisk behandling"
          link="/info/behandlinger/tannbleking"
          icon={EstetiskBehandling}
        />
      </div>
    </div>
  )
}

export default OurServices
