import { useNavigate } from "react-router-dom"
import Logo from "../../../assets/images/logo/logo_white_cropped.webp"

type InfoBoxProps = {
  title: string
  buttonText: string
  className?: string
  buttonClass?: string
  style?: React.CSSProperties
  onClick?: () => any
  children: any
}

const FrontInfo = ({ className }: { className?: string }) => {
  const navigate = useNavigate()

  const InfoBox = ({
    title,
    buttonText,
    className,
    style,
    buttonClass,
    onClick = () => {},
    children,
  }: InfoBoxProps) => (
    <div
      className={`relative bg-red-400 text-light px-8 lg:px-14 py-8 shadow-lg flex flex-col ${className}`}
      style={style}
    >
      <img
        src={Logo}
        alt="logo"
        className="absolute top-0 right-0 m-8 h-8 w-8"
      />
      <h2 className="w-full text-xl lg:text-2xl md:text-3xl font-bold mb-6 pr-4">
        {title}
      </h2>
      <div className="font-bold mb-4 whitespace-pre-line">{children}</div>
      <div className="flex">
        <button
          className={`text-primary-accent bg-light rounded-full p-2 px-4 font-bold shadow-lg hover:!bg-primary-accent hover:!text-light ${buttonClass}`}
          style={{ minWidth: "12rem" }}
          onClick={onClick}
        >
          {buttonText}
        </button>
      </div>
    </div>
  )

  return (
    <div
      className={`grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-8 px-6 lg:px-32 ${className}`}
    >
      <InfoBox
        title="Velkomsttilbud"
        buttonText="Bestill time"
        buttonClass="!bg-focus hover:!text-focus"
        style={{ borderTopLeftRadius: "4rem", backgroundColor: "#466761" }}
        onClick={() => (window.location.href = "https://myanita.no/clinic/41")}
      >
        <p>Vi ønsker alle våre nye pasienter velkommen.</p>
        <p>
          Akkurat nå er det -35% på undersøkelse hos tannlege inkludert 2
          røntgen og rens til 890kr.
        </p>
      </InfoBox>
      <InfoBox
        title="Studentrabatt"
        buttonText="Se behandlinger"
        className="!text-light"
        style={{ borderTopLeftRadius: "4rem", backgroundColor: "#648b84" }}
        onClick={() => navigate("/info/behandlinger")}
      >
        <p>
          Vi tilbyr fastpris på undersøkelse til alle studenter: generell sjekk
          inkludert 2 røntgen og enkel rens til kun 790kr.
        </p>
        <p>All behandling -10% (gjelder på ordinær pris).</p>
      </InfoBox>
      <InfoBox
        title="Tannlegevakt Trondheim"
        buttonText="Les mer"
        className="!text-primary-accent"
        style={{ borderBottomRightRadius: "4rem", backgroundColor: "#aec5c1" }}
        onClick={() => navigate("/info/tannlegevakt")}
      >
        <p>
          Har du behov for akutt hjelp? Vi er her for deg. Du finner oss på
          Lade, like ved City Lade. Vår vakttelefon er åpen alle kvelder,
          helligdager og helger (10:00 - 18:00).
        </p>
        <br />
        <p className="text-lg">Vakttelefon 73 52 00 00</p>
      </InfoBox>
      <InfoBox
        title="Tannlegeskrekk Trondheim"
        buttonText="Les mer"
        className="!text-primary-accent"
        style={{ borderBottomRightRadius: "4rem", backgroundColor: "#dbebdd" }}
        onClick={() => navigate("/info/tannlegeskrekk")}
      >
        <p>
          Vi forstår at å dra til tannlegen er for mange knyttet opp til mye
          angst. Vi tar oss god tid til deg og tilpasser oss ditt behov. Våre
          ansatte er alle erfarne og har jobbet mange år med pasienter med
          forskjellige grader av tannlegeskrekk (odontofobi).
        </p>
      </InfoBox>
    </div>
  )
}

export default FrontInfo
