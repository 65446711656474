// Assets
import { Helmet } from "react-helmet"
import toothImplant from "../../assets/images/tannimplantat.webp"
import OrderButton from "../base/order-button/order-button"

const ToothImplant = () => {
  return (
    <div className="info w-full center-content p-8 text-dark">
      <Helmet>
        <title>Tannimplantat Trondheim</title>
      </Helmet>
      <div className="max-w-screen-xl">
        <div className="center-content flex-col mb-8 text-center">
          <h1>
            Tannimplantat Trondheim: Alt du trenger å vite om tannimplantat
          </h1>
          <p className={`!mb-8 mt-2`}>
            Tannimplantater representerer den mest avanserte teknologien for å
            erstatte tapte tenner og gjenopprette både funksjon og estetikk i
            munnen.
          </p>
          <img
            src={toothImplant}
            alt="tannimplantat"
            className="w-full max-h-[60vh] object-cover object-center"
          />
        </div>
        <div className="mb-8">
          <h2>Hva er et tannimplantat?</h2>
          <p>
            Et implantat fungerer som en kunstig tannrot, vanligvis laget av
            titan, og er designet for å fungere som feste for nye tenner i form
            av kroner, broer eller helproteser.
          </p>
        </div>
        <div className="mb-8">
          <h2>Fordelene med tannimplantat</h2>
          <h3>Langvarig løsning</h3>
          <p>
            Tannimplantater tilbyr en permanent løsning for den manglende tannen
            og kan vare livet ut med riktig pleie. De erstatter tapte tenner og
            er designet for å integreres fullstendig med kjevebenet, noe som gir
            en stabil og holdbar løsning som ikke er avhengig av de
            omkringliggende tennene for støtte.
          </p>
          <h3>Naturlig utseende og funksjon</h3>
          <p>
            Implantater ser ut og føles som egne tenner og gjenoppretter nesten
            100% av tyggefunksjonen. Dette gir brukeren mulighet til å nyte et
            ubegrenset utvalg av mat uten bekymring for at implantatet løsner,
            noe som er en vanlig bekymring med tradisjonelle proteser.
          </p>
          <h3>Bevaring av ansiktsstruktur</h3>
          <p>
            Å miste tenner kan føre til at kjevebenet gradvis brytes ned, noe
            som kan føre til at ansiktets form endrer seg overtid.
            Tannimplantater stimulerer og bevarer kjevebenet, noe som hjelper
            til med å opprettholde ansiktsstrukturen.
          </p>
        </div>
        <div className="mb-8">
          <h2>Alternative behandlingsmuligheter for manglende tenner</h2>
          <p>
            Manglende tenner kan erstattes gjennom ulike behandlingsmetoder,
            avhengig av pasientens behov og munnhelsens tilstand. De mest
            vanlige alternativene inkluderer tannimplantater, tannbroer og
            tannproteser. Hvert alternativ har sine fordeler og ulemper, og det
            viktigste er å velge en løsning som best oppfyller den enkeltes
            behov.
          </p>
          <h3>Tannimplantater vs. tannbro</h3>
          <p className="!mb-1 font-bold">
            Tannimplantater anses ofte som den beste løsningen fordi de fungerer
            som en kunstig tannrot og tilbyr en varig løsning uten å belaste de
            tilstøtende tennene. Fordelene inkluderer:
          </p>
          <ul className="list-disc pl-4 mb-4">
            <li>
              Faste tenner som ser og føles naturlige ut. Bevarer kjeveben og
            </li>
            <li>
              hindrer benresorpsjon. Skader ikke friske nabotenner siden
              implantatet
            </li>
            <li>
              står selvstendig. Lav risiko for komplikasjoner; hovedrisikoen er
              at
            </li>
            <li>
              implantatet ikke fester seg, men det kan vanligvis erstattes.
            </li>
          </ul>
          <p className="!mb-1 font-bold">
            Ulemper med tannimplantater kan inkludere:
          </p>
          <ul className="list-disc pl-4 mb-4">
            <li>Høyere kostnader sammenlignet med andre alternativer.</li>
            <li>
              Lengre behandlingstid på grunn av nødvendigheten av
              benhelingsprosessen.
            </li>
            <li>
              Krever god munnhygiene og kan ha dårligere prognose hos røykere
              eller pasienter med visse helseproblemer.
            </li>
          </ul>
          <p className="!mb-1 font-bold">
            Tannbroer er et godt alternativ når de manglende tennene er omringet
            av andre tenner som kan fungere som støtte. Fordelene med en bro
            inkluderer:
          </p>
          <ul className="list-disc pl-4 mb-4">
            <li>
              Raskere prosedyre og installasjon sammenlignet med implantater.
            </li>
            <li>Kostnadseffektiv sammenlignet med implantater.</li>
          </ul>
          <p className="!mb-1 font-bold">Ulempene inkluderer:</p>
          <ul className="list-disc pl-4 mb-4">
            <li>
              Behov for å slipe ned tilstøtende sunne tenner for å feste broen,
              noe som kan svekke disse tennene over tid.
            </li>
            <li>Rengjøring kan være mer utfordrende enn med et implantat.</li>
            <li>
              Kan kreve utskifting etter en periode på grunn av slitasje eller
              skade på støttetenner.
            </li>
          </ul>
        </div>
        <div className="mb-8">
          <h2>Tannproteser som alternativ</h2>
          <p className="!mb-1 font-bold">
            Tannproteser tilbyr en mer økonomisk løsning og er særlig nyttig for
            personer med mange manglende tenner eller de som ikke er kandidater
            for kirurgiske inngrep. Fordelene med proteser inkluderer:
          </p>
          <ul className="list-disc pl-4 mb-4">
            <li>Lavere kostnad og raskere løsning.</li>
            <li>Ingen behov for kirurgiske inngrep.</li>
          </ul>
          <p className="!mb-1 font-bold">Ulempene kan være:</p>
          <ul className="list-disc pl-4 mb-4">
            <li>
              Proteser sitter ikke fast, noe som kan føre til ubehag og
              problemer med å spise.
            </li>
            <li>
              Krever regelmessig justering og vedlikehold for å sikre passform
              og komfort.
            </li>
          </ul>
        </div>
        <div className="mb-8">
          <h2>Vurdering av risikofaktorer</h2>
          <p className="!mb-1 font-bold">
            For tannimplantatbehandling er det viktig å vurdere flere
            risikofaktorer som kan påvirke behandlingens suksess:
          </p>
          <ul className="list-disc pl-4 mb-4">
            <li>
              Periodontitt: Pasienter med tannkjøttsykdommer som periodontitt
              har en høyere risiko for komplikasjoner som periimplantitt, en
              tilstand som kan føre til tap av bein rundt implantatet.
            </li>
            <li>
              Røyking: Røyking kan redusere helingsevnen og suksessraten for
              implantater. Det anbefales sterkt at røykere reduserer eller
              slutter før implantatbehandling.
            </li>
            <li>
              Utilstrekkelig beinmengde: Mangel på tilstrekkelig beinvev kan
              kreve ytterligere prosedyrer som benoppbygging før implantater kan
              plasseres.
            </li>
          </ul>
          <p>
            Hver pasient må få en individuell vurdering for å identifisere den
            beste løsningen basert på deres spesifikke behov og helsetilstand.
            Det er viktig å diskutere alle alternativer og potensielle risikoer
            med din tannlege for å gjøre en informert beslutning om hvilken
            tannerstatningsmetode som er riktig for deg.
          </p>
        </div>
        <div className="mb-8">
          <h2>Tannimplantat prosess</h2>
          <h3>1. Konsultasjon og planlegging</h3>
          <p>
            Det første trinnet er en omfattende evaluering av pasientens
            munnhelse, hvor tannlegen vurderer om det er tilstrekkelig kjeveben
            tilgjengelig til å støtte implantatet. Dette kan innebære
            bildediagnostikk som røntgen eller CT for å planlegge nøyaktig hvor
            implantatene skal settes.
          </p>
          <h3>2. Kirurgisk prosedyre</h3>
          <p>
            Operasjonen utføres under lokalbedøvelse for å sikre minimalt med
            ubehag for pasienten. Innsetting av implantat involverer å lage et
            lite snitt i tannkjøttet for å plassere implantatet direkte inn i
            kjeven. Hvis det er utilstrekkelig kjeveben, kan tilleggsprosedyrer
            som benoppbygging være nødvendig.
          </p>
          <h3>3. Helingsprosess</h3>
          <p>
            Etter åha satt inn implantatet, følger en helingsperiode hvor
            kjevebenet vokser rundt implantatet i en prosess kjent som
            osseointegrasjon. Dette kan ta flere måneder, og det er viktig at
            implantatet ikke belastes i denne perioden.
          </p>
          <h3>4. Avsluttende restaurering</h3>
          <p>
            Når implantatet har integrert fullstendig med kjevebenet, festes en
            permanent tannrestaurering – enten en krone for å erstatte én tann,
            en bro for å erstatte flere tenner, eller en helprotese for en helt
            tannløs kjeve.
          </p>
        </div>
        <div className="mb-8">
          <h2>Tannimplantat pris</h2>
          <p>
            Pris for tannimplantat i Trondheim avhenger av flere faktorer,
            inkludert antallet implantater som kreves, eventuell benoppbygging,
            og type protese som velges. En enkeltstående implantatkrone starter
            typisk rundt 30,000-35,000 NOK. Det er viktig å få en detaljert
            konsultasjon for å motta et nøyaktig prisestimat.
          </p>
        </div>
        <div className="mb-8">
          <h2>Vanlige spørsmål om tannimplantater</h2>
          <h3>Hvem er kandidater for tannimplantater?</h3>
          <p>
            De fleste over 18 år med god generell helse og tilstrekkelig
            kjeveben er potensielle kandidater for tannimplantater. Unge
            pasienter må vente til kjevebenet har sluttet å vokse før de kan
            vurdere implantater.
          </p>
          <h3>Er inngrepet smertefullt?</h3>
          <p>
            De fleste pasienter opplever lite til ingen smerte under selve
            inngrepet siden operasjonen utføres under lokalbedøvelse. Etterpå
            kan mildt ubehag behandles med reseptfrie smertestillende.
          </p>
          <h3>Hvor lang tid tar prosessen?</h3>
          <p>
            Total varighet fra start til slutt kan variere fra tre til ni
            måneder, avhengig av pasientens individuelle helingsprosess og
            eventuelle tilleggsbehandlinger.
          </p>
        </div>
        <div className="mb-8">
          <h2>Ta kontakt for mer informasjon</h2>
          <p>
            Er du interessert i å lære mer om hvordan tannimplantater kan
            gjenopprette ditt smil og tyggefunksjon? Ta kontakt med Lade
            Tannhelsesenter for en grundig evaluering og skreddersydd
            behandlingsplan. Vi er her for å svare på alle dine spørsmål og
            hjelpe deg gjennom hvert trinn i prosessen mot dine nye tenner.
          </p>
        </div>
        <div className="center-content mt-12 mb-10">
          <OrderButton />
        </div>
      </div>
    </div>
  )
}

export default ToothImplant
