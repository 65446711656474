import { FaTimes } from "react-icons/fa"

interface CloseButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  size?: number
}

const CloseButton = (props: CloseButtonProps) => {
  const { size, ...rest } = props

  return (
    <button {...rest}>
      <FaTimes size={size || 32} />
    </button>
  )
}

export default CloseButton
