import { useEffect, useState } from "react"
import CloseButton from "../base/close-button/close-button"
import EditChatUser from "./components/edit-chat-user"
import IconButton from "../base/icon-button/icon-button"
import { FaEdit } from "react-icons/fa"
import { v4 as uuidv4 } from "uuid"
import ChatWindow from "./components/chat-window"
import { doc, setDoc } from "firebase/firestore"
import { firestore } from "../../App"
import { ChatConfig } from "../../services/chat-service"
import { chatService } from "../../services/services"
import ChatAutoHelp from "./components/chat-auto-help"

export type Chat = {
  id: string
  email: string
  phone: string
  lastMessage?: string
  lastMessageAt?: string
  lastMessageFromEmployee?: boolean
  deletedAt?: string
}

export type ChatUser = {
  id?: string
  email?: string
  phone?: string
}

export type ChatMessage = {
  chatId: string
  employee?: {
    id: string
    email: string
  }
  message: string
  createdAt: string
}

type ChatWindowProps = {
  show: boolean
  setShowChat: (showChat: boolean) => void
}

const _Chat = ({ show: showChat, setShowChat }: ChatWindowProps) => {
  const [chatConfig, setChatConfig] = useState<ChatConfig | null>(null)
  const [chatUser, setChatUser] = useState<ChatUser | null>(null)
  const [editingChatUser, setEditingChatUser] = useState(false)
  const [useAutoHelp, setUseAutoHelp] = useState(true)

  // Hooks
  useEffect(() => {
    chatService
      .getConfig()
      .then((config) => {
        setChatConfig(config)

        const chatUser = localStorage.getItem("chatUser")
        if (chatUser) {
          setChatUser(JSON.parse(chatUser))
          return
        }

        setChatUser({
          id: uuidv4(),
        })
        setEditingChatUser(true)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [])

  async function onSaveChatUser(chatUser: ChatUser) {
    if (!chatUser.id) return console.error("ChatUser ID is missing")

    await setDoc(doc(firestore, "chats", chatUser.id), chatUser, {
      merge: true,
    })

    setChatUser(chatUser)
    setEditingChatUser(false)
    localStorage.setItem("chatUser", JSON.stringify(chatUser))
  }

  // Render
  return (
    <div
      className={`fixed z-40 bottom-0 right-0 w-full center-content md:justify-end md:mr-8 pointer-events-none`}
    >
      <div
        className={`${
          showChat ? " h-[90vh] md:h-[30rem]" : "h-0"
        } w-[20rem] flex flex-col shadow-lg transition-all duration-200 pointer-events-auto`}
      >
        <div
          className="h-12 flex justify-between items-center px-4 bg-primary-accent"
          style={{ borderRadius: "1rem 1rem 0 0" }}
        >
          <span className="text-white font-bold text-xl select-none">Chat</span>
          <CloseButton
            className="text-white hover:scale-110 mt-1"
            size={28}
            onClick={() => setShowChat(false)}
          />
        </div>
        {useAutoHelp && (
          <ChatAutoHelp onActivateChat={() => setUseAutoHelp(false)} />
        )}
        {!useAutoHelp && editingChatUser && (
          <EditChatUser chatUser={chatUser} onSave={onSaveChatUser} />
        )}
        {!useAutoHelp &&
          (!editingChatUser && chatConfig?.chatEnabled ? (
            <div className="flex-1 flex flex-col">
              <div className="bg-primary-accent border-t-2 text-gray-100 py-2 px-4 flex items-center justify-between">
                <div>
                  <p>E-post: {chatUser?.email}</p>
                  <p>Tlf: {chatUser?.phone}</p>
                </div>
                <IconButton
                  className="hover:scale-110"
                  onClick={() => setEditingChatUser(true)}
                >
                  <FaEdit size={24} />
                </IconButton>
              </div>
              {chatUser && (
                <div className="flex-1">
                  <ChatWindow chatUser={chatUser} chatConfig={chatConfig} />
                </div>
              )}
            </div>
          ) : (
            <div className="flex-1 center-content bg-light text-lg">
              {(!chatConfig?.chatEnabled && chatConfig?.chatDisabledMessage) ??
                "Chatten er midlertidig stengt"}
            </div>
          ))}
      </div>
    </div>
  )
}

export default _Chat
