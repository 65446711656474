import { AxiosInstance } from "axios"
import { v4 as uuidv4 } from "uuid"

export default class ImageService {
  private client: AxiosInstance

  constructor(client: AxiosInstance) {
    this.client = client
  }

  public uploadImageFromUrl = async (url: string) => {
    // base64 url to blob
    const blob = await fetch(url).then((r) => r.blob())

    let fileName = uuidv4()

    // Add file extension to file name
    fileName += "." + blob.type.split("/")[1]

    // Create file from blob
    const file = new File([blob], fileName, { type: blob.type })

    return this.uploadImageFromFile(file)
  }

  public uploadImageFromFile = async (file: File) => {
    const formData = new FormData()
    formData.append("image", file)

    const response = await this.client.post("/admin/image", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    return response.data
  }
}
