import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { InfoTextMap } from "../../services/text-service"
import "./info.scss"

// Components
import ReactQuill from "react-quill"
import LoadingCover from "../base/loading-cover/loading-cover"
import NotFound from "../not-found/not-found"

type InfoProps = {}

const Info = (props: InfoProps) => {
  const { textId } = useParams()
  const [infoTexts, setInfoTexts] = useState<InfoTextMap | null>(null)

  useEffect(() => {
    setInfoTexts({}) // TODO: Fetch from backend
  }, [])

  if (!infoTexts) return <LoadingCover className="!h-screen" />
  if (!textId || !infoTexts[textId]) return <NotFound />
  return (
    <div className="w-full center-content">
      <div className="max-w-[90vw] md:mt-8">
        <ReactQuill
          className="quill-editor"
          theme="snow"
          readOnly={true}
          value={infoTexts[textId].content}
          modules={{ toolbar: false }}
        />
      </div>
    </div>
  )
}

export default Info
