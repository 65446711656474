import React, { useRef, useState } from "react"
import PulseButton from "../base/pulse-button/pulseButton"

// Assets
import LogoAnimation from "../../assets/images/logo/logo.gif"

import { FaCalendarAlt, FaMapMarkedAlt } from "react-icons/fa"
import { BsHeadset } from "react-icons/bs"
import { IoMenu } from "react-icons/io5"
import { useNavigate } from "react-router-dom"
import useClickAway from "../../hooks/click-away"
import Menu from "./menu"
import MapAndDirections from "../map-and-directions/map-and-directions"
import CloseButton from "../base/close-button/close-button"

const Navbar = React.forwardRef<HTMLDivElement, any>(
  ({ onOpenPrivacy }, ref) => {
    // State
    const [showMenu, setShowMenu] = useState(false)
    const [showMapAndDirections, setShowMapAndDirections] = useState(false)

    const showOrderButton = window.location.pathname !== "/bestill"

    // Hooks
    const navigate = useNavigate()
    const navMenuRef = useRef(null)
    const mapAndDirectionsRef = useRef(null)

    useClickAway({
      ref: navMenuRef,
      onClickAway: () => setShowMenu(false),
    })

    useClickAway({
      ref: mapAndDirectionsRef,
      onClickAway: () => setShowMapAndDirections(false),
    })

    // Methods
    const navigateWrapper = (path: string, options?: { refresh?: boolean }) => {
      setShowMenu(false)
      setShowMapAndDirections(false)
      if (options?.refresh) {
        window.location.href = path
      } else {
        navigate(path)
      }
    }

    const scrollToLocationInfo = () => {
      const infoRef = document.getElementById("location-info")
      infoRef?.scrollIntoView({ behavior: "smooth" })
    }

    return (
      <div
        ref={ref}
        className="fixed z-30 top-0 w-full shadow-lg flex flex-col items-center bg-background-light"
      >
        <div
          className={`absolute h-full top-0 z-10 ${
            !showMapAndDirections && "hidden"
          }`}
        >
          <div className="h-full w-24" />
          <div
            ref={mapAndDirectionsRef}
            className="relative max-w-screen-lg bg-background-light border-2 border-primary-accent text-primary-accent rounded-lg shadow-lg mt-4"
          >
            <MapAndDirections />
            <CloseButton
              className="absolute top-0 right-0 bg-white border border-primary-accent rounded-full p-1 m-4 mr-6 lg:mr-4"
              onClick={() => setShowMapAndDirections(false)}
            />
          </div>
        </div>
        <div className="h-full w-full max-w-screen-xl">
          <div className="relative flex flex-row justify-between items-center h-full">
            <div className="absolute w-full center-content pointer-events-none">
              <div
                className="hidden md:flex flex-col items-center text-primary-accent w-48 cursor-pointer pointer-events-auto"
                onClick={() => {
                  window.location.href = "https://myanita.no/clinic/41"
                }}
              >
                <PulseButton color="focus">
                  <FaCalendarAlt size={24} className="text-primary-accent" />
                </PulseButton>
                <span className="text-primary-accent font-bold">
                  Bestill time
                </span>
              </div>
              <div
                className="hidden md:flex flex-col items-center text-primary-accent w-48 cursor-pointer pointer-events-auto"
                onClick={scrollToLocationInfo}
              >
                <PulseButton>
                  <BsHeadset size={24} />
                </PulseButton>
                <span className="text-primary-accent font-bold">
                  Ring 73 52 00 00
                </span>
              </div>
              <div
                className="hidden md:flex flex-col items-center text-primary-accent w-48 cursor-pointer pointer-events-auto"
                onClick={() => setShowMapAndDirections(true)}
              >
                <PulseButton>
                  <FaMapMarkedAlt size={24} />
                </PulseButton>
                <span className="text-primary-accent font-bold">
                  Kart & veibeskrivelse
                </span>
              </div>
            </div>
            <div
              className="relative px-4 select-none cursor-pointer text-primary"
              onClick={() => navigate("/")}
            >
              <img
                src={LogoAnimation}
                alt="logo"
                className="p-4 w-48 md:w-64"
              />
            </div>
            <div className="flex flex-row items-center">
              <div className="relative flex flex-col items-center mx-8 md:!mr-2 cursor-pointer text-primary-accent">
                <div
                  className="center-content flex-col pr-6"
                  onClick={() => setShowMenu(true)}
                >
                  <IoMenu size={32} className="text-primary-accent" />
                  <span className="text-primary-accent font-bold">Meny</span>
                </div>
                <div className={`h-full w-full`} ref={navMenuRef}>
                  <div
                    className="fixed right-0 overflow-hidden transition-all duration-500 ease-in-out"
                    style={{
                      maxHeight: showMenu ? "600px" : "0px",
                      marginTop: "-4rem",
                    }}
                  >
                    <Menu
                      onNavigateWrapper={navigateWrapper}
                      onSetShowMenu={setShowMenu}
                    />
                  </div>
                </div>
              </div>
              <button
                className="hidden md:flex flex-col border-2 border-primary-accent rounded px-2 py-1 text-lg font-bold items-end text-primary-accent cursor-pointer"
                onClick={() =>
                  window.open(
                    "https://myanita.no/login?clinic_source=41",
                    "_blank"
                  )
                }
              >
                Min side
              </button>
            </div>
          </div>
        </div>
        {showOrderButton && (
          <div className="md:hidden flex justify-around items-center text-primary-accent w-full h-20">
            <div
              className="flex flex-col items-center text-primary-accent w-48"
              onClick={() => {
                window.location.href = "https://myanita.no/clinic/41"
              }}
            >
              <PulseButton color="focus">
                <FaCalendarAlt size={24} className="text-primary-accent" />
              </PulseButton>
              <span className="text-primary-accent font-bold">Bestill</span>
            </div>
            <div className="flex flex-col items-center text-primary-accent w-48">
              <a href="tel:73520000" className="center-content flex-col">
                <PulseButton>
                  <BsHeadset size={24} />
                </PulseButton>
                <span className="text-primary-accent font-bold">
                  73 52 00 00
                </span>
              </a>
            </div>
            <div
              className="flex flex-col items-center text-primary-accent w-48"
              onClick={() => setShowMapAndDirections(true)}
            >
              <PulseButton>
                <FaMapMarkedAlt size={24} />
              </PulseButton>
              <span className="text-primary-accent font-bold">Kart</span>
            </div>
          </div>
        )}
      </div>
    )
  }
)

export default Navbar
