import "./pulseButton.scss"

const PulseButton = ({
  color = "primary",
  children,
  size = 50,
  onClick = () => {},
}: {
  color?: "focus" | "primary" | "primary-accent"
  children?: any
  size?: string | number
  onClick?: () => void
}) => {
  return (
    <div
      className={`pulse-${color} rounded-full flex items-center justify-center cursor-pointer`}
      style={{ width: size, height: size }}
      onClick={onClick}
    >
      {children}
    </div>
  )
}

export default PulseButton
