import TopicalCard, { ComingSoonCard } from "./components/topical-card"

// Constants
import articles from "../../constants/articles"
import { Helmet } from "react-helmet"

const Topical = () => {
  return (
    <div className="center-content flex-col">
      <Helmet>
        <title>Aktuelt</title>
      </Helmet>
      <div className="w-full max-w-screen-xl p-8">
        <h1 className="text-4xl text-primary-accent mb-4">Aktuelt</h1>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          {articles.map((article) => (
            <TopicalCard
              key={article.id}
              id={article.id}
              title={article.title}
              description={article.description}
              image={article.image}
            />
          ))}
          <ComingSoonCard />
        </div>
      </div>
    </div>
  )
}

export default Topical
