import React, { Fragment, useEffect, useState } from "react"
import { Treatment as TreatmentType } from "../../services/treatment-service"
import { treatmentService } from "../../services/services"
import LoadingCover from "../base/loading-cover/loading-cover"
import Treatment from "./components/treatment"
import { useParams } from "react-router-dom"
import { Helmet } from "react-helmet"

const Treatments = () => {
  const [loading, setLoading] = useState(true)
  const [treatments, setTreatments] = useState<TreatmentType[]>([])

  const params = useParams<{ treatment: string }>()
  const treatmentParam = params.treatment

  // Mounted
  useEffect(() => {
    treatmentService.getTreatmentList().then((treatments) => {
      setTreatments(
        treatments.sort(
          (a: TreatmentType, b: TreatmentType) =>
            (a.order || 99) - (b.order || 99)
        )
      )
      setLoading(false)
    })
  }, [])

  if (loading || !treatments) return <LoadingCover />

  function toLowerId(name: string) {
    return name
      .toLowerCase()
      .replace(/æ/g, "a")
      .replace(/ø/g, "o")
      .replace(/å/g, "a")
      .replace(/ /g, "")
  }

  return (
    <Fragment>
      <Helmet>
        <title>Behandlinger</title>
      </Helmet>
      <div className="center-content flex-col p-4 mt-16">
        <div className="w-full max-w-screen-lg mb-8">
          <h1 className="text-3xl text-primary-accent">Behandlinger</h1>
          <div className="grid grid-cols-1 lg:grid-cols-2 grid-cols-min gap-4 mt-8 mb-8">
            {treatments.map((treatment, index) => (
              <Treatment
                key={index}
                treatment={treatment}
                defaultOpen={treatmentParam === toLowerId(treatment.name)}
              />
            ))}
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Treatments
