import { useContext, useState } from "react"
import { adminService } from "../../../services/services"
import AdminContext from "../../../contexts/admin-context"
import { useNavigate } from "react-router-dom"
import LoadingCover from "../../base/loading-cover/loading-cover"

const AdminLogin = () => {
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [passwordMessage, setPasswordMessage] = useState<string | null>(null)

  const { setAdmin } = useContext(AdminContext)

  const navigate = useNavigate()

  async function tryLogin() {
    setLoading(true)
    const admin = await adminService
      .login(email, password)
      .catch((err) => {
        if (err.response.status === 400) {
          console.error("Server error:", err)
        }
        if (err.response.status === 404) {
          setPasswordMessage("Feil brukernavn eller passord")
        }
        return
      })
      .finally(() => setLoading(false))

    if (!admin) return

    setAdmin(admin)
    navigate("/ansatt/chat")
  }

  return (
    <div className="w-screen h-[70vh] center-content flex-col">
      <LoadingCover
        show={loading}
        className="fixed top-0 left-0 h-screen w-screen"
      />
      <p className="text-2xl mb-2">Ansatt login</p>
      <input
        placeholder="email"
        className="border-2 border-primary focus:border-primary-accent rounded-lg px-2 py-1 text-lg mb-4"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") tryLogin()
        }}
      />
      <input
        type="password"
        placeholder="passord"
        className="border-2 border-primary focus:border-primary-accent rounded-lg px-2 py-1 text-lg"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") tryLogin()
        }}
      />
      <button
        className="bg-primary-accent text-gray-100 rounded shadow m-2 p-2 font-bold hover:bg-primary mb-4"
        onClick={tryLogin}
      >
        Logg inn
      </button>
      <button
        className="bg-primary-accent text-gray-100 rounded shadow m-2 p-2 font-bold hover:bg-primary mb-4"
        onClick={() => (window.location.href = "/")}
      >
        Til forsiden
      </button>
      {passwordMessage && (
        <p className="text-danger font-bold">{passwordMessage}</p>
      )}
    </div>
  )
}

export default AdminLogin
