import React, { useState, useEffect } from "react"
import moment, { Moment } from "moment"
import { FaTimes } from "react-icons/fa"

type Availability = {
  [day: string]: DayAvailability[]
}

type DayAvailability = {
  available: boolean
  time: Moment
}

const SelectTime = () => {
  const [selectedWeekMonday, setSelectedWeekMonday] = useState<Moment | null>(
    null
  )
  const [dayAvailabilities, setDayAvailabilities] = useState<Availability>({})
  const [selectedTime, setSelectedTime] = useState<DayAvailability | null>(null)

  useEffect(() => {
    const currentMonday = moment().startOf("isoWeek")
    setSelectedWeekMonday(currentMonday)
    const availability: Availability = {}
    let currentDay = currentMonday.clone()
    for (let i = 0; i < 30; i++) {
      let start = currentDay.clone().add(8, "hours").add(-30, "minutes")
      availability[currentDay.toISOString()] = Array.from(Array(17).keys()).map(
        (i) => {
          start = start.clone().add(30, "minutes")
          return {
            available: true,
            time: start,
          }
        }
      )

      currentDay.add(1, "day")
    }
    setDayAvailabilities(availability)
  }, [])

  if (!selectedWeekMonday) return null
  return (
    <div className="">
      <div className="grid grid-cols-5 gap-2">
        {Array.from(Array(5).keys()).map((i) => (
          <div key={i}>
            <div className="text-center">
              <div className="text-sm text-primary-accent">
                {selectedWeekMonday.clone().add(i, "days").format("DD. MMM")}
              </div>
              <div className="text-sm text-primary-accent mb-1">
                {selectedWeekMonday
                  .clone()
                  .add(i, "days")
                  .format("dddd")
                  .toUpperCase()}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="grid grid-cols-5">
        {Array.from(Array(5).keys()).map((i: any, index: number) => (
          <div key={i}>
            {dayAvailabilities[
              selectedWeekMonday.clone().add(index, "days").toISOString()
            ].map((availability, index) => (
              <div key={index} className="center-content p-1">
                <button
                  className={`border border-light text-lg font-bold bg-nature-light text-light text-center flex-1 rounded ${
                    selectedTime?.time.isSame(availability.time) &&
                    "!bg-focus !text-primary-accent !border-primary-accent"
                  }`}
                  onClick={() => setSelectedTime(availability)}
                >
                  {availability.time.format("HH:mm")}
                </button>
              </div>
            ))}
          </div>
        ))}
      </div>
      <div
        className={`fixed bottom-0 z-10 left-0 w-full h-0 bg-light border-t-2 border-primary-accent shadow 0 transition-all duration-300 ${
          selectedTime && "!p-2 !h-64"
        }`}
      >
        <div className="flex flex-row justify-between mb-2">
          <div className="flex flex-row">
            <h2 className="text-xl text-primary-accent font-bold capitalize mr-4">
              {selectedTime?.time.format("dddd DD. MMMM")}
            </h2>
            <h2 className="text-xl text-nature-light font-bold capitalize">
              {selectedTime?.time.format("HH:mm")}
            </h2>
          </div>
          <button onClick={() => setSelectedTime(null)}>
            <FaTimes className="text-primary-accent" size={28} />
          </button>
        </div>
        <div className="border-t-[1px] bg-primary-accent w-full border-dashed" />
        <div className="flex flex-row justify-between pt-4">
          <div>
            Her kommer det en oversikt over behandlingen du har valgt og hvem
            som er tannlegen. Kanskje pris her?
          </div>
          <button className="px-4 py-2 text-xl font-bold rounded bg-[#dbebdd] text-primary-accent hover:bg-[#aec5c1]">
            Bestill time
          </button>
        </div>
      </div>
    </div>
  )
}

export default SelectTime
